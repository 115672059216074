import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
    name: 'session',
    initialState: {
        sessionId: null
    },
    reducers: {
        setSession: (state = {}, action) => {
            // console.log('reducer called ' + action.payload.sessionId)
            state.sessionId = action.payload.sessionId
        },
        endSession: (state) => {
            state.sessionId = null
        }
    }
})


export const { setSession, endSession } = sessionSlice.actions
export const selectSession = state => state.session
export default sessionSlice.reducer