import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        fullName: null,
        email: null,
        _id: null,
        ward: null,
        profileId: null,
        token: null,
        following: null,
        country: "CA"
    },
    reducers: {
        setUser: (state = [], action) => {
            // const { fullName, email, _id, preference, displayName, verified, following } = action.payload;

            const keys = Object.keys(action.payload)
            // console.log('keys >> ', keys)
            keys.forEach((key) => {
                state[key] = action.payload[key]
            })

            // if (fullName) state.fullName = fullName;
            // if (email) state.email = email;
            // if (preference.privacy) state.privacy = preference.privacy;
            // if (displayName) state.displayName = displayName;
            // if (_id) state._id = _id;
            // if (verified) state.verified = verified;
            // if (preference.defaultView) state.defaultView = preference.defaultView;
            // if (following) state.following = following
        },
        logoutUser: (state) => {
            state.fullName = null;
            state.email = null;
            state._id = null;
            state.ward = null;
            state.profileId = null;
            state.token = null;
            state.following = null;
            state.country = "CA";
            state.city = null;
            state.state = 'loggedOut';
            
            // state.defaultView = null;
        },
        setDefaultView: (state, action) => {
            const { defaultView } = action.payload;
            state.defaultView = defaultView;
        },
        changeCountry: (state, action) => {
            state.country = action.payload;
        },
        changeSubscriptions: (state, action) => {
            const { emailPolicy } = action.payload;
            state.emailPolicy = emailPolicy
        }
    }
});


export const {
    setUser, //entire user changes based on keys
    logoutUser, //all fields are set to null except defaultview
    setDefaultView, //only to change defaultview
    changeSubscriptions, //only to change userSubscription
    changeCountry
} = userSlice.actions;

export const selectUser = state => state.user;

export default userSlice.reducer;
