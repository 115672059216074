import SearchBar from "../Input/SearchBar"
import {Avatar, Drawer, Image, Menu} from 'antd';
import Parrly from '../../assets/parrly.png';
import { useEffect, useRef, useState } from "react";
import './Header.css';
import { Link, useNavigate } from "react-router-dom";
import Search from "../Input/Search";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {Button} from 'antd';
import { logoutUser, selectUser, setUser } from "../../redux/features/User/UserSlice";
import WindowSize from "../windowSize";
import { getCookie } from "../LandingPage/LandingPage";
import CountrySelector from "../CountrySelector/CountrySelector";
import { LoginOutlined, LogoutOutlined, MenuOutlined, ProfileOutlined, SettingOutlined, StarOutlined, UserDeleteOutlined, UserOutlined } from "@ant-design/icons";
import { Turn as Hamburger } from 'hamburger-react';
import constants from "../../constants/constants";
import MenuDesktop from "../../Menu/Menu";

const Header = ({searchResults, changeSearch, query, activeElement}) => {
    const [focus, setFocus] = useState();
    const headerRef = useRef();
    const user = useSelector(selectUser);
    const navigate = useNavigate();
    const [country, setCountry] = useState();
    const dispatch = useDispatch();
    const [width, height] = WindowSize();
    useEffect(() => {
        if (!user._id && getCookie('jwt')){
            axios.get('/api/user/loginValidate', {withCredentials: true, credentials: 'include'})
            .then(res => {
                dispatch(setUser(res.data))
            })
            .catch(err => console.log(err));
        }
    }, [])
    return (
        width > 740 ?
        //Desktop View
        <div className="header" ref={headerRef}>
        <Link to='/'><Avatar src={Parrly} size={50} shape="square" /></Link>
        <SearchBar getCountry={country}/>
        {user._id && user.state === "loggedIn" ? <Button shape='round' onClick={() => {
            axios.post('/api/user/logoutUser', {}, {withCredentials: true, credentials: 'include'})
            .then(res => {
                dispatch(logoutUser(user));
            })
        }}>Logout</Button> : <Button shape="round" onClick={() => navigate('/login')}>Login</Button>}
        <MenuDesktop chooseCountry={(country) => setCountry(country)}/>
        </div>
        :
        //Mobile view
        <div className="header" ref={headerRef}>
        <Link to='/'><Avatar src={Parrly} size={50} shape="square"/></Link>
        <Search />
        <MobileMenu chooseCountry={country => setCountry(country)}/>
       {/*{user._id && user.state === "loggedIn" ? <Button shape="round" onClick={() => {
            axios.post('/api/user/logoutUser', {}, {withCredentials: true, credentials: 'include'})
            .then(res => {
                dispatch(logoutUser(user));
            })
        }}>Logout</Button> : <Button shape="round" onClick={() => navigate('/login')}>Login</Button>}
                {user.profileId && <Button shape="round" onClick={() => navigate(`/profile/${user.webToken}/talk`)}>Profile</Button>}
                <CountrySelector country={country => {setCountry(country)}}/>*/}

        </div>
    )
}
export const MobileMenu = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectCountry, setCountry] = useState(getCookie('country'));
    const user = useSelector(selectUser);
    const [visible, setVisible] = useState(false);
    const countryTabs = [
        {
            key: "CA",
            label: "Canada",
            icon: <Image preview={false} className="flagIcon" src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Canada_%28Pantone%29.svg/1200px-Flag_of_Canada_%28Pantone%29.svg.png" />,
            onClick: (key) => onClickCountry(key)
        },
        {
            key: "US",
            label: "United States",
            icon: <Image preview={false} className="flagIcon" src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"/>,
            onClick: (key) => onClickCountry(key)
        },
    ]
    const tabs = [

        user.profileId && {
            key: 'Profile',
            label: 'Profile',
            icon: <ProfileOutlined/>,
            onClick: () => {setVisible(!visible);navigate(`/profile/${user.webToken}/talk`)}
        },
        {
            key: 'Settings',
            label: 'Settings',
            icon: <SettingOutlined/>,
            onClick: () => {setVisible(!visible);navigate(`/settings/general`)}
        },
        {
            key: 'Country',
            label: 'Country ' + selectCountry,
            icon: selectCountry === "CA" ? 
            <Image preview={false} src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Canada_%28Pantone%29.svg/1200px-Flag_of_Canada_%28Pantone%29.svg.png" className="flagIcon"/>
             : <Image preview={false} src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg" className="flagIcon"/>,
            children: countryTabs,
        },
        user._id && user.state === "loggedIn" ?
        {
            key: "Logout",
            label: "Logout",
            icon: <LogoutOutlined/>,
            onClick: () => { axios.post('/api/user/logoutUser', {}, {withCredentials: true, credentials: 'include'}).then(res => {
                dispatch(logoutUser(user));
            })}
        } : {
            key: 'Login',
            label: 'Login',
            icon: <LoginOutlined />,
            onClick: () => navigate('/login')
        },
    ]

    const onClickCountry = (key) => {
        console.log(key, 'KEY');
        setCountry(key.key);
        document.cookie = `country=${key.key}`
        props.chooseCountry(key.key);
    }
    return (

        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Hamburger toggled={visible} color={constants.purple} type='link' onClick={() => {setVisible(!visible)}}
            onToggle={setVisible}
            ></Hamburger>
            <div className='drawer-wrapper' style={{}}>
                <Drawer
                    // style={{ position: 'absolute', top: 40 }}
                    placement="top"
                    height="100%"
                    width="100%"
                    closable={false}
                    // onClose={this.onClose}
                    open={visible}
                    mask={false}
                    destroyOnClose={true}
                    zIndex={1}
                // style={{ top: 70 }}
                >
                    <Menu
                    items={tabs}
                        // onClick={this.handleClick}
                        defaultSelectedKeys={[selectCountry]}
                        defaultOpenKeys={[selectCountry]}
                        triggerSubMenuAction='click'
                        style={{ position: 'relative', top: 40 }}
                        mode='inline'
                    >
                        {/*
                            tabs.map(
                                ({ key, text, icon, onClick }) => (
                                    key === "Country" ? 
                                    <Menu.SubMenu  title={`Country: ${selectCountry}`} icon={selectCountry === "CA" ? countryTabs[0].icon : countryTabs[1].icon}>
                                    {countryTabs.map((country, i) => (
                                        <Menu.Item key={i} onClick={() => onClickCountry(country.key)} itemIcon={country.icon}>
                                            {country.text}
                                        </Menu.Item>
                                    ))}
                                    </Menu.SubMenu>
                                    :
                                    <Menu.Item
                                    onClick={() => {onClick(); setVisible(!visible)}}
                                    key={key}
                                    icon={icon}>{text}
                                </Menu.Item>
                                )
                            )
                                    */}
                    </Menu>
                </Drawer>
            </div>
        </div >

    )
}
export default Header;