import { ArrowLeftOutlined } from "@ant-design/icons";
import { Input, Button, Card } from "antd";
import axios from "axios";
import { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import constants from "../../constants/constants";
import { AnalyticsContext } from "../../context/context";
import Header from "../Header/Header";
import './Login.css';



const Forgot = () => {


    const [email, setEmail] = useState('');
    const [errorMsg, setError] = useState('');
    const navigate = useNavigate();
    const loginUser = useSelector(state => state.user)
    const gtagActive = useContext(AnalyticsContext);
    useEffect(() => {
        if (loginUser._id){
            navigate('/');
        }
    }, [])
    const handleSubmit = async () => {

        if (!(email.includes(".") && email.includes("@") && email.length > 5)) {
            setError("Invalid e-mail")
            return;
        }
        const res =  await axios.post(`/api/user/forgot-password`, {email : email})
        alert("An e-mail was sent to the account if it exists");
        constants.gtagDefined() && gtagActive && window.gtag('event', 'forgot-password', {'email': email})
        navigate(-1);
    }
    return (
        <div>
                        <Header/>
        <div className="forgotPass" >
            <Card title="Forgot Password" className="forgotCard">
                <ArrowLeftOutlined onClick={() => navigate(-1)}/>
                <div style={{textAlign: 'center', marginBottom: '2vh'}} className={(errorMsg) ? 'displayErrorDiv' : 'hideErrorDiv'}>
                    {errorMsg}
                </div>
                <div className="login" style={{flexDirection: 'column', gap: '1vh'}}>
        <p>Enter your e-mail</p>
        <Input value={email} onChange={e => setEmail(e.target.value)}/>
        <Button style={{alignSelf: 'center'}} onClick={handleSubmit}>Submit</Button>
        </div>
        </Card>
        </div>
        </div>

    )
}
export default Forgot;