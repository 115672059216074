import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Input, message } from "antd";
import axios from "axios";
import { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom"
import constants from "../../constants/constants";
import { AnalyticsContext } from "../../context/context";


const SignupVerification = () => {

    const {token, id} = useParams();
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const gtagActive = useContext(AnalyticsContext);
    const getUser = useSelector(state => state.user);
    useEffect(() => {
        if (getUser._id){
            navigate('/');
        }
        else if (!token){
            navigate('/');
        } else {
            axios.get(`/api/user/verify-signup?token=${token}&id=${id}`)
            .then(res => {
                if (res.data.message){
                    setShowPassword(true);
                    setUser(res.data.user);
                } else {
                    constants.gtagDefined() && gtagActive && window.gtag('event', 'voter-signup')
                    message.success("Account has been verified");
                    navigate('/login');
                }
            }).catch(err => {
                message.error("Couldn't verify account")
                navigate('/login');
            });
        }
    }, [])
    const handlePassword = async () => {
        const res = await axios.post('/api/user/setCandidatePassword', {...user, password} ).catch(err => alert("Server error occurred. Please try again with a stable connection or e-mail us at support@parrly.com") )
         if (res.status == 200){
            constants.gtagDefined() && gtagActive && window.gtag('event', 'candidate-signup')
             alert("Successfully setup your account. Please continue to login");
             navigate('/login');
         } else {
             alert("Error occurred, make sure password is more than 8 characters");
         }
     }
    return (
        !showPassword ? 
        <div>

        </div>
        : 
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1vh', width: '100vw', height: '100vh'}}>
            <p>Set up your password</p>
            <Input style={{width: "50%"}} minLength={8} value={password} onChange={(text) => setPassword(text.target.value)} suffix={password.length >= 8 ? <CheckCircleOutlined style={{color: 'green'}}/> : <CloseCircleOutlined style={{color: 'red'}}/>}/>
            <Button onClick={handlePassword}>Submit</Button>    
        </div>
    )

}
export default SignupVerification;