

import { useState, useEffect } from "react";
import { Button, Card } from "antd";
import SignupVoter from "./SignupVoter";
import SignupCandidate from "./SignupCandidate";
import Header from "../Header/Header";
import './Signup.css';
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/features/User/UserSlice";
import { useNavigate } from "react-router-dom";
const initTabList = [
    {
        key: 'voter',
        tab: 'Voter'
    },
    {
        key: 'candidate',
        tab: 'Candidate'
    }
]
const SignupChoice = () => {
    const [signupVoter, setSignupVoter] = useState(true);
    const [showButtons, setShowTabs] = useState(true);
    const [activeTab, setActiveTab] = useState('voter');
    const [tabList, setTabList] = useState(initTabList)
    const loginUser = useSelector(selectUser);
    const navigate = useNavigate();
    useEffect(() => {
        if(loginUser._id){
            navigate(-1)
        }
    })
    const handleSubmit = (key) => {

        let newTabList = [...tabList];
        if (key == "voter"){
            newTabList.splice(1, 2);
        } else {
            newTabList.splice(0, 1);
        }
        setTabList(newTabList);
    }

    const contentList = {
        voter: <SignupVoter callback={key => handleSubmit(key)}/>,
        candidate: <SignupCandidate callback={key => handleSubmit(key)}/>
    }
    return (
        <div>
            <Header/>
        <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 100}}>
        <Card className="signupCard" tabList={tabList} title="Sign up" activeTabKey={activeTab} onTabChange={key => setActiveTab(key)}>
            <div style={{display: 'flex', justifyContent: 'center' ,alignItems: 'center', width: "100%"}}>
            {contentList[activeTab]}
            </div>
        </Card>
        </div>
        </div>

    )
}
export default SignupChoice;