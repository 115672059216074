import { configureStore } from '@reduxjs/toolkit';
//import counterReducer from '../features/counter/counterSlice';
import userReducer from './features/User/UserSlice';
import sessionReducer from './features/Session/SessionSlice'
import wardReducer from './features/Wards/WardSlice';
import locationReducer from './features/Location/LocationSlice';

export default configureStore({
  reducer: {
    //counter: counterReducer,
    user: userReducer,
    session: sessionReducer,
    ward: wardReducer,
    location: locationReducer
  }
});
