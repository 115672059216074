
import { useNavigate } from 'react-router-dom';
import Parrly from '../../assets/parrly.png'
import constants from '../../constants/constants';


const Modal = ({modalRef, onClick, style}) => {
    const navigate = useNavigate();
    return (
        <div ref={modalRef} className="modal" onClick={onClick} >
        <div style={style}>
        <h1 style={{textAlign: 'center'}}>Download now for a better experience</h1>
        <img src={Parrly} id="logo"/>
            <a target="_blank" href='https://play.google.com/store/apps/details?id=com.keyptech.parrly'><img id="googleplay" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
            <h1 style={{color: constants.purple, cursor: 'pointer', userSelect: 'none'}} onClick={() => navigate("/login")}>Or Login </h1>
            </div>
        </div>
    )
}
export default Modal;