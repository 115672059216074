import { ArrowLeftOutlined, ArrowRightOutlined, SendOutlined } from '@ant-design/icons';
import {Avatar, Button, Card, Comment, Input} from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import constants from '../../constants/constants';
import { selectUser } from '../../redux/features/User/UserSlice';
import ReplyIcon from '../../assets/replyIcon.png';
import { useLocation, useNavigate } from 'react-router-dom';


const PostCard = ({post, callback, commentId, style, profile}) => {
    const [baseComments, setBaseComments] = useState([]);
    const [replyComments, setReplyComments] = useState([]);
    const [showComments, setShowComments] = useState(false);
    const [replyTo, setReply] = useState(false);
    const [comment, setComment] = useState('');
    const [replyToId, setReplyTo] = useState();
    const [replyName, setReplyName] = useState(null);
    const [threadParent, setThreadParent] = useState(null);
    const [newComment , setNewComment] = useState('');
    const user = useSelector(selectUser);
    const location = useLocation();
    const navigate = useNavigate();
    const getComments = () => {
    axios.post(`/api/comment/getBaseComments`, {postId: post._id})
    .then(res => {
        if (commentId){
            res.data.forEach((com, i) => {
                if (com._id === commentId){

                    let linkedComment = com;
                    res.data[i] = res.data[0];
                    res.data[0] = linkedComment;
                }
            })
            setShowComments(true);
        }
        if (post.commentCount > res.data.length){
            axios.post(`/api/comment/getReplyComments`, {postId: post._id})
            .then(res2 => {
                if (commentId){
                    res2.data.forEach((com, i) => {
                        if (com._id === commentId){ 
                            res2.data[i] = res.data[0];
                            res2.data[0] = com;                       
                            res.data.forEach((baseComment, i) => {
                                if (baseComment._id === com.threadParent){
                                    res.data[i] = res.data[0];
                                    res.data[0] = baseComment;
                                }
                            })
                        }
                    })
                }
                setReplyComments(res2.data);
            }).catch(err => console.log(err));
        }
        setBaseComments(res.data);
    }).catch(err => console.log(err));
    }
    const getCommentsReplies = () => {
        let getBaseComments = baseComments
        if (post.commentCount > getBaseComments.length){
            axios.post(`/api/comment/getReplyComments`, {postId: post._id})
            .then(res => {
                if (commentId){
                    res.data.forEach((com, i) => {
                        if (com._id === commentId){ 
                            res.data[i] = res.data[0];
                            res.data[0] = com;                       
                            getBaseComments.forEach((baseComment, i) => {
                                if (baseComment._id === com.threadParent){
                                    getBaseComments[i] = getBaseComments[0];
                                    getBaseComments[0] = baseComment;
                                }
                            })
                        }
                    })
                }
                setReplyComments(res.data);
            }).catch(err => console.log(err));
        }
    }
    const handleReplyClick = (comment) => {
        console.log(comment, 'comment');
        if (replyTo && replyToId === comment._id){
            setReply(false);
            return
        }
        setReply(true);
        setReplyTo(comment._id);
        setReplyName(comment.name)
        setThreadParent(comment.threadParent ? comment.threadParent : comment._id);
        setComment(`@${comment.name} `);
    }
    const sendEmailToPoster = async (comment) => {
        const res = await axios.post('/api/user/sendEmailToPoster', comment)
    }
    const sendEmailToReply = async (comment) => {
        const res = await axios.post('/api/user/sendEmailReplyComment', comment)
    }
    const handleSubmit = () => {
        if (comment.length - `@${replyName} `.length < 5){
            alert("Write a longer comment");
            return;
        }
        if (replyTo){
            const newReplyComment = {
                name: user.fullName,
                comment: comment,
                replyTo: replyToId,
                threadParent: threadParent,
                profileId: post.profileId._id ,
                postId: post._id,
                userId: user._id,
                token: user.token,
                date: Date.now()
            }
            axios.post(`/api/comment/create`, newReplyComment)
            .then(res => {
                newReplyComment.date = new Date(newReplyComment.date).toISOString();
                let newReplyComments = [...replyComments];
                newReplyComments.push(newReplyComment);
                setReplyComments(newReplyComments, 'newreplycomments');
                post.commentCount++;
                sendEmailToReply(res.data)
                sendEmailToPoster(res.data);
            }).catch(err => {alert("Error")
        console.log(err);
        });
        } else {
            alert("Error occurred");
        }
        setReply(false);
        setReplyTo(null);
        setReplyName(null);
        setThreadParent(null);
        setComment('');
    }
    const handleNewComment = () => {
        if (user._id == null){
            callback();
            return;
        }
        if (newComment.length < 5){
            alert("Write a longer comment");
            return;
        }
        const newBaseComment = {
            name: user.fullName,
            comment: newComment,
            profileId: post.profileId._id,
            postId: post._id,
            userId: user._id,
            token: user.token,
            date: Date.now()
        }
        axios.post('/api/comment/create', newBaseComment)
        .then(res => {
            alert("Submitted")
            newBaseComment.date = new Date(newBaseComment.date).toISOString();
                let newBaseComments = [...baseComments];
                newBaseComments.unshift(newBaseComment);
                setBaseComments(newBaseComments);
                post.hotComments[0] = newBaseComment
                post.commentCount++;
                sendEmailToPoster(res.data)
        })
        .catch(err => {console.log(err);alert("not submitted")});
        setNewComment('');

    }
    const handleChangeText = (text) => {
        if (replyTo && text.target.value.substring(0, `@${replyName} `.length) !== `@${replyName} `){
            setReply(false);
            setReplyTo(null);
            setReplyName(null);
            setThreadParent(null);
            setComment('');
        } else {
            setComment(text.target.value);
        }
    }

    if (!post.hotComments){
        post.hotComments = [];
    }
    useEffect(() => {
        if (commentId){
            getComments();
            //getCommentsReplies();
        }
    }, [])
    const showProfileOnPost = () => {
        const text = post.typePost === "Post" ? "posted" : post.typePost === "QnA" ? "asked" : '';
        return (
            <div onClick={() => navigate(`/profile/${post.profileId.webToken}/talk`)} style={{cursor: 'pointer', display: 'flex', justifyContent :'flex-start' ,alignItems: 'center', gap: '2vw'}}>
                <span>{post.userId.fullName} {text} on {post.profileId.fullName} </span>
                <Avatar size={50} src={constants.bucketUrl + post.profileId.profilePicture}/>

            </div>
        )
    }
    console.log(profile);
    return (
        <Card style={style} loading={!post} title={(post.profileId._id !== profile._id && showProfileOnPost()) || (post.typePost == 'Post' ? `${post.userId.fullName} posted` : post.typePost == 'QnA' ? `${post.userId.fullName} asked` : `${post.profileId.fullName}`) }>
            { post.postMedia && <img style={{width: '50%'}} src={ constants.bucketUrl + post.postMedia} />}
            <p>{post.post}</p>
            {post.commentCount > 0 && <p><br></br>Comments:</p>}
            {<Input placeholder='Comment anything...' suffix={<SendOutlined onClick={handleNewComment}/>} onChange={text => setNewComment(text.target.value)} value={newComment} onClick={() => user._id == null && callback()}></Input>}
            {!showComments && post.hotComments.map((comment, i) => <Comment key={i} author={comment.name} content={<p id="commentContent">{comment.comment}{/*<img style={{width: 20}} onClick={() => user._id !=null ? function(){getComments();setShowComments(!showComments);handleReplyClick(comment);}(): callback()} src={ReplyIcon}/>*/}</p>} datetime={comment.date.toString().split("T")[0]}/>)}
            {showComments && baseComments.map((baseComment, j) => {
               return <Comment style={{boxShadow: commentId === baseComment._id && `1px 1px 2px 2px ${constants.purple}`}} key={baseComment._id || j} suffix={<SendOutlined/>} author={baseComment.name} content={<p id="commentContent">{baseComment.comment}<img style={{width: 20}} onClick={() => user._id !=null ? handleReplyClick(baseComment) : callback()} src={ReplyIcon}/></p>} datetime={baseComment.date.toString().split("T")[0]} >
                {replyTo && replyToId === baseComment._id && <Input suffix={<SendOutlined onClick={handleSubmit}/>} rows={5} cols={50} onChange={handleChangeText}  value={comment} placeholder={`Reply to ${baseComment.name}`}></Input>}
                {replyComments.filter(comment => comment.threadParent === baseComment._id)
                .map((reply,i )=> 
                <Comment style={{boxShadow: commentId === reply._id && `1px 1px 2px 2px ${constants.purple}`, padding: "4px"}} key={reply._id || i} author={reply.name} content={<p id="commentContent">{reply.comment}<img style={{width: 20}} onClick={() => user._id !=null ? handleReplyClick(reply) : callback()} src={ReplyIcon}/></p>} datetime={reply.date.toString().split("T")[0]}>
                {replyTo && replyToId === reply._id && <Input suffix={<SendOutlined onClick={handleSubmit}/>} onChange={handleChangeText}  value={comment} placeholder={`Reply to ${baseComment.name}`}></Input>}
                </Comment>)}
               </Comment>
            })}
            {post.commentCount > 0 && <p className='viewComments' onClick={() => {!showComments && getComments();setShowComments(!showComments)}}>{showComments ? "Hide comments" : "View comments"} ({post.commentCount})</p>}
        </Card>
    )
}
export default PostCard;