import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd"
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import constants from "../../constants/constants";
import { selectUser, setUser } from "../../redux/features/User/UserSlice";
import Header from "../Header/Header";
import { getCookie } from "../LandingPage/LandingPage";
import './Settings.css';

const SecuritySettings = () => {
    const navigate = useNavigate();
    const [oldPass, setOldPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!user._id && getCookie('jwt')){
            axios.get('/api/user/loginValidate', {withCredentials: true, credentials: 'include'})
            .then(res => {
                if (!res.data){
                    navigate('/');
                } else {
                    console.log("RESDATA", res.data);
                    dispatch(setUser(res.data))
                }

            })
            .catch(err => console.log(err));
        }
    }, [])
    const submitPassword = () => {
        if (oldPass.length < 8 || newPass.length < 8) {
            message.info("Passwords must be of length 8 or more")
            return;
        }
        if (oldPass === newPass ){
            message.info("Must select a new password");
            setNewPass('');
            setConfirmPass('');
            return;
        }
        if (newPass !== confirmPass){
            message.info("Passwords must match");
            setNewPass('');
            setConfirmPass('');
            return;
        }
        axios.post(`/api/user/updateUserPassword?token=${user.token}`, {oldPass, newPass})
        .then(res => {
            message.success("Updated password successfully");
            setOldPass('');
            setNewPass('');
            setConfirmPass('');
        }).catch(err => {
            console.log(err);
            message.error(err.response.data);
        })
    }
    return (
        <div>
            <Header/>
        <div className="settings ant-card-bordered">
        <div className="sideBar">
            <h1 onClick={() => navigate(`/settings/general`)} >General</h1>
            <h1 onClick={() => navigate(`/settings/security`)} style={{color: constants.purple}}>Security</h1>
        </div>
            <Form >
            <Form.Item>
                Old Password:
                <Input value={oldPass} onChange={e => setOldPass(e.target.value)} suffix={oldPass.length >= 8 ? <CheckCircleOutlined style={{color: 'green'}}/> : <CloseCircleOutlined style={{color: 'red'}}/>}/>
            </Form.Item>
            <Form.Item>
                New Password:
                <Input value={newPass}  onChange={e => setNewPass(e.target.value)} suffix={newPass.length >= 8 ? <CheckCircleOutlined style={{color: 'green'}}/> : <CloseCircleOutlined style={{color: 'red'}}/>}/>
            </Form.Item>
            <Form.Item>
                Confirm New Password:
                <Input value={confirmPass}  onChange={e => setConfirmPass(e.target.value)} suffix={confirmPass.length >= 8 ? <CheckCircleOutlined style={{color: 'green'}}/> : <CloseCircleOutlined style={{color: 'red'}}/>}/>
            </Form.Item>
            <Form.Item style={{display: 'flex', justifyContent: 'center'}}>
            <Button onClick={submitPassword}>Submit</Button>
            </Form.Item>
            </Form>
            </div>
        </div>
    )
}
export default SecuritySettings;