import axios from "axios"
import { useEffect, useState } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Donate from "./components/Donate/Donate"
import Elections from "./components/Elections/Elections"
import Feed from "./components/Feed/Feed"
import LandingPage from "./components/LandingPage/LandingPage"
import Forgot from "./components/Login/Forgot"
import Login from "./components/Login/Login"
import ResetPassword from "./components/Login/ResetPassword"
import Profile from "./components/Profile/Profile"
import EmailVerification from "./components/Settings/EmailVerification"
import SecuritySettings from "./components/Settings/SecuritySettings"
import Settings from "./components/Settings/Settings"
import SignupChoice from "./components/Signup/SignupChoice"
import SignupVerification from "./components/Signup/SignupVerification"
import DataPolicy from "./components/Terms/DataPolicy"
import Terms from "./components/Terms/TermsOfUse"
import { AnalyticsContext } from "./context/context"

const Router = () => {
    const [check, setCheck] = useState();
    const GA4PUBKEY = "G-B9XDV07FLM"
    useEffect(() => {
        axios.get(`https://www.googletagmanager.com/gtag/js?id=G-B9XDV07FLM`, {withCredentials: false})
                .then(() => {setCheck(true);console.log("GOOD")})
                .catch((err) => {setCheck(false);console.log(err)})
    }, [])
    return (
        <AnalyticsContext.Provider value={check}>
        <div>
            <BrowserRouter>
            <Routes>
            <Route path="/donate" element={<Donate/>}/>
                <Route index element={<LandingPage/>}/>
                <Route path='/data-policy' element={<DataPolicy/>}/>
                <Route path='/terms' element={<Terms/>}/>
                <Route path='/profile/:token'>
                    <Route path='talk' element={<Profile tab={0}/>}/>
                    <Route path='qna' element={<Profile tab={1}/>}/>
                    <Route path='news' element={<Profile tab={2}/>}/>
                    <Route path='feed' element={<Profile tab={3}/>}/>
                </Route>
                <Route path='/forgot-password' element={<Forgot/>} />
                <Route path='/reset-password/:token/:id' element={<ResetPassword/>}/>
                <Route path='/login' element={<Login promptMessage={"Connect with candidates"}/>}/>
                <Route path='/signup' element={<SignupChoice/>}/>
                <Route path='*' element={<h1>Error 404</h1>}/>
                <Route path="/signup-verification/:token/:id" element={<SignupVerification/>}/>
                <Route path="/email-verification/:token/:id" element={<EmailVerification/>}/>
                <Route path='/elections/:id' element={<Elections/>}/>
                <Route path='/settings'>
                    <Route path='general' element={<Settings/>}/>
                    <Route path='security' element={<SecuritySettings/>}/>
                </Route>
                <Route path='/myfeed' element={<Feed/>} />
            </Routes>
            </BrowserRouter>
        </div>
        </AnalyticsContext.Provider>
    )
}
export default Router;