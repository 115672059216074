import { Modal, Spin } from "antd";
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import constants from "../../constants/constants";
import { selectUser, setUser } from "../../redux/features/User/UserSlice";
import Header from "../Header/Header"
import ProfileCard from "../Profile/ProfileCard";
import WindowSize from "../windowSize";
import ProfilePostCard from "../Profile/ProfilePostCard";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { getCookie } from "../LandingPage/LandingPage";

 

const Feed = () => {
    const [posts, setPosts] = useState([]);
    const modalRef = useRef();
    const [width, height] = WindowSize();
    let mobileView = false;
    const user = useSelector(selectUser);
    const navigate = useNavigate();
    const [profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    if (width <= 740){
        mobileView = true;
   }
   const handleScroll = (e) => {
    console.log("SCROLL");
   }
   const showWardPosts = () => {

   }
   const getFollowerPosts = () => {
    console.log(user);
    const profileType = user.mayorUpdates == true ? "mayor,councillor" : "councillor"
    axios.get(`/api/post/getFeedPosts?userId=${user._id}&profileType=${profileType}`)
    .then(res => {
        setPosts(res.data);
        console.log('AYO22e12e');

      if (res.data.length < 1){
        console.log('AYO');
        getWardProfilesPost();
      }
    })
  }
  const getWardProfilesPost = () => {
    axios.get(`/api/profile/getProfilesByWard?ward=${user.ward}`)
    .then(res => {
        console.log("RES", res);
        setProfiles(res.data)})
    .catch(err => console.log(err));
  }
  useEffect(() => {
    if (!user._id && getCookie('jwt')){
        axios.get('/api/user/loginValidate', {withCredentials: true, credentials: 'include'})
        .then(res => {
            console.log(res, 'res');
            if (!res.data){
                navigate('/');
            } else {
                dispatch(setUser(res.data))
            }
        })
        .catch(err => {
            navigate('/');
        });
    } 
}, [])
useEffect(() => {
    getFollowerPosts();
    
}, [user])
console.log(posts, 'posts', profiles, profiles.length > 0);
   document.onscroll = handleScroll;
    return (
        !mobileView ? 
        //Desktop View
        <div className="profileContainer"  onScroll={(e) => handleScroll(e)}>
        <Header />
        <div className="profileWrapper">
            <div className="sideBar">
                    {user.state === "loggedIn" && <h1 onClick={() => navigate(`/myfeed`)} style={{color: constants.purple}}>Feed</h1>}
            </div>
            <div className="profile">
                {posts.length < 1 && <p style={{fontSize: "2rem", margin: 0, marginTop: '5vh'}}>Got anything to say about your local politicians?</p>}
                {
                posts.length < 1 ? 
                profiles.map(profile => <ProfileCard profile={profile} key={profile._id}/>)
                :
                posts.map(post => <ProfilePostCard post={post} key={post._id} profile={{_id: post.profileId}}/>)}
                <Spin spinning={loading} size={'large'} tip="Retrieving more content..." indicator={<LoadingOutlined/>}/>
            </div>
        </div>
    </div>
        : 
        <div>
         {/*Mobile view*/}
        <div>       
        <div className="profileContainer" onScroll={(e) => handleScroll(e)}>
            <Header />
            <div className="profileWrapper">
                <div className="profile" style={{width: "100%", padding: "1rem"}}>
                {posts.length < 1 && <p style={{fontSize: "2rem", margin: 0, marginTop: '5vh'}}>Got anything to say about your local politicians?</p>}
                    {
                posts.length < 1 ? 
                profiles.map(profile => <ProfileCard profile={profile} key={profile._id} />)
                :
                
                posts.map(post => <ProfilePostCard post={post} key={post._id} profile={{_id: post.profileId}}/>) 
                }
                    {loading && <LoadingOutlined size={100}/>}
                </div>
            </div>
        </div>
        <div className="bottomTabs">
            {user.state === "loggedIn" && <h1 onClick={() => navigate(`/myfeed`)} style={{color: 'lightgrey'}}>Feed</h1>}
        </div>

        </div>  
        </div>
    )
}
export default Feed;