

// import '../Homepage/Homepage.css'
import { Input, Button, Form } from 'antd';
import React, { useContext, useState } from 'react';
import './Login.css';
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios'


//redux-imports
//redux-imports
import { setUser } from '../../redux/features/User/UserSlice';
import {
    useDispatch,
    //useSelector
} from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { setLocation } from '../../redux/features/Location/LocationSlice';
import constants from '../../constants/constants';
import { AnalyticsContext } from '../../context/context';


const LoginModal = (props) => {
    const { history} = props;
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // eslint-disable-next-line
    const [remember, setRemember] = useState(true);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const gtagActive = useContext(AnalyticsContext);
    const handleSubmit = async () => {
        setLoading(true)
        let _error = null;
        if (!email.includes('@') || !email.includes("."))
            _error = ("Invalid Email Address")
        // else if (error) setError(null); //if email includes mentioned chars, but error is still set, unset it
        if (password.length < 8 || !password)
            _error = ("Invalid password");
        setError(_error)
        if (!_error) {
            //do something
            axios.post(`/api/user/loginUser`, {email: email, password: password}, {withCredentials: true, credentials: 'include'})
                .then(res => { 
                    if (res.data.status === "failed"){

                        setError(res.data.message);
                    } else {
                        dispatch(setUser(res.data.user));
                        dispatch(setLocation(res.data.location))
                        document.cookie = `country=${res.data.user.country}`
                        navigate('/');
                        constants.gtagDefined() && gtagActive && window.gtag('event', 'login', {'name': res.data.user.fullName})
                    }
                    setLoading(false);

                })
                .catch(err => {
                    setError(err.response.data || "Invalid Email or Password")
                    setLoading(false)
                    
                })

        }else setLoading(false)
    }
    return (
        
            <div className="loginModalForm">
               {
                   props.props.promptMessage && (
                       <div style={{ marginBottom: 20 }}>
                           <strong>{props.props.promptMessage}</strong>
                       </div>
                   )
               }
                <Link to='/'>
                    <h1>Parrly</h1>
                </Link>
                <div className={(error) ? 'displayErrorDiv' : 'hideErrorDiv'}>
                    {error}
                </div>
                <Form

                    initialValues={{ remember: true }}
            >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input
                            size="large"
                            placeholder="Email"
                            value={email}
                            style={{borderRadius: 10 }}
                            onChange={(e) => setEmail(e.target.value)}
                            onPressEnter={() => handleSubmit()}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input
                            size="large"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                            style={{borderRadius: 10 }}
                            placeholder="Password"
                            onPressEnter={() => handleSubmit()}
                        />
                    </Form.Item>
                    <p onClick={() => navigate('/forgot-password')} style={{cursor: 'pointer'}}>Forgot password</p>

                    <Form.Item>
                    <Button
                        className="formSubmitBtn"
                        shape="round"
                        htmltype="submit"
                        onClick={e => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                        type="primary"
                        size="large"
                        block
                        disabled={loading}
                    >{!loading ? "Login" : <LoadingOutlined style={{ color: "black", marginLeft: "8px" }} />}</Button>
                                        <Button
                        className="formSubmitBtn"
                        shape="round"
                        onClick={e => {
                            navigate("/signup")
                        }}
                        style={{marginTop: 20}}
                        type="primary"
                        size="large"
                        block
                    >Sign Up</Button>
                </Form.Item>
                
                 <div className="loginModalFormOptions">
                        {/* <Checkbox
                            onChange={e => { setRemember(!remember) }}
                        checked={remember}
                        disabled
                        >Remember me</Checkbox> */}
                    </div>

                
                <div className="registerLinkBtn">
                    <strong>
                        </strong>
                </div>
                
                {/*<div
                    style={{ fontSize: "x-small", marginTop: "8px"}}
                >
                    This site is protected by reCAPTCHA and the Google
                        <a href="https://policies.google.com/privacy">{" " }Privacy Policy{" " }</a> and
                    <a href="https://policies.google.com/terms">{" " }Terms of Service{" " }</a> apply.

                </div>*/}
            </Form>

        </div>

    )
}

export default LoginModal
