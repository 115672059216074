import { Button, Input } from "antd"
import axios from "axios";
import { useState, useEffect } from "react";
import Header from "../Header/Header";
import './Signup.css';
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../redux/features/User/UserSlice";

const SignupCandidate = (props) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [verification, setshowVerification] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('');
    const [showPass, setPass] = useState(false);
    const [user, setCandidate] = useState({});
    const loginUser = useSelector(selectUser);
    useEffect(() => {
        if(loginUser._id){
            navigate(-1)
        }
    }, [])

    const handleSubmit = async () => {
        setLoading(true);
    const res = await axios.post(`/api/user/sendActivateEmail`, {email: email}).catch(err => alert("Server error occurred. Please try again with a stable connection or e-mail us at support@parrly.com"));
    if (res.data.status == "success"){
        alert(res.data.message)
        navigate('/login');
    } else {
        alert(res.data.message)
    }
    setLoading(false);
    }
    return (
            <div className="signupForm">
                {!verification && !showPass && 
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1vh', width: "100%"}}>
                <p>Enter your e-mail provided by City Hall</p>
            <Input placeholder="example@parrly.com" value={email} onChange={(text) => setEmail(text.target.value)} type="text"/>
            <Button onClick={handleSubmit} loading={loading}>Submit</Button></div>}
            </div>
    )
}

export default SignupCandidate;