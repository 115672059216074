import React, { useEffect } from 'react';
import Header from '../Header/Header';
const DataPolicy = (props) => {
    
    useEffect(() => {
        document.title = "Data Policy - Parrly"
    }, [])

    return (<div>
                   <Header/>
        <div style={{margin:"5em"}}>
    <p>This policy describes the information we process to support Parrly.</p>
    <h1><b>I. What kinds of information do we collect?</b></h1>
    <p>To provide the Parrly service, we must process information about you. </p>
    <b>Things you and others do and provide.</b>
    <ul>
        <li>
        <b>
        Information and content you provide.
        </b>
        <p>
        We collect the content, communications and other information you provide when you use Parrly, including when you sign up for an account, create or share content, and message or communicate with others. This can include information in or about the content you provide (like metadata), such as the location of a photo or the date a file was created. It can also include what you see through features we provide. Our systems automatically process content and communications you and others provide to analyze context.
        </p>
        </li>
        <li>
        <b>
        Networks and connections. 
        </b>
        <p>
        We collect information about the people, accounts, hashtags and accounts you are connected to and how you interact with them across Parrly, such as people you communicate with the most or accounts you are involved with. 
        </p>
        </li>
        <li>
        <b>
        Your Usage.
        </b>
        <p>
        We collect information about how you use Parrly, such as the types of content you view or engage with; the features you use; the actions you take; the people or accounts you interact with; and the time, frequency and duration of your activities. For example, we log when you're using and have last used Parrly, and what posts, videos and other content you view on Parrly. 
        </p>
        </li>
        <li>
        <b>
        Information about transactions made on Parrly.
        </b>
        <p>
        If you use Parrly for purchases or other financial transactions, we collect information about the purchase or transaction. This includes payment information, such as your credit or debit card number and other card information; other account and authentication information; and billing, shipping and contact details.
        </p>
        </li>
        <li>
        <b>
        Things others do and information they provide about you. 
        </b>
        <p>
        We also receive and analyze content, communications and information that other people provide when they use Parrly. This can include information about you.
        </p>
        </li>
    </ul>
    <b>Device Information</b>
    <p>
    As described below, we collect information from and about the computers, phones, and other web-connected devices you use that integrate with Parrly, and we combine this information across different devices you use. For example, we use information collected about your use of Parrly on your phone to better personalize the content or features you see when you use Parrly on another device, such as your laptop or tablet.
    </p>
    <p>Information we may obtain from these devices includes: </p>
    <ul>
        <li>
        <b>
        Device attributes:
        </b>
        <p>
        information such as the operating system, hardware and software versions, battery level, signal strength, available storage space, browser type, app and file names and types, and plugins.
        </p>
        </li>
        <li>
        <b>
        Device operations: 
        </b>
        <p>
        information about operations and behaviors performed on the device, such as whether a window is foregrounded or backgrounded, or mouse movements (which can help distinguish humans from bots).
        </p>
        </li>
        <li>
        <b>
        Identifiers:
        </b>
        <p>
        unique identifiers, device IDs or other identifiers unique to Parrly associated with the same device or account.
        </p>
        </li>
        <li>
        <b>
        Device signals:
        </b>
        <p>
        Bluetooth signals, and information about nearby Wi-Fi access points, beacons, and cell towers.
        </p>
        </li>
        <li>
        <b>
        Data from device settings:
        </b>
        <p>
        information you allow us to receive through device settings you turn on, such as access to your GPS location, camera or photos.
        </p>
        </li>
        <li>
        <b>
        Network and connections:
        </b>
        <p>
        information you allow us to receive through device settings you turn on, such as access to your GPS location, camera or photos.
        </p>
        </li>
        <li>
        <b>
        Cookie Data:
        </b>
        <p>
        data from cookies stored on your device, including cookie IDs and settings.
        </p>
        </li>
    </ul>

    <h1><b>II. How do we use this information?</b></h1>
    <p>
    We use the information we have (subject to choices you make) as described below and to provide and support Parrly and related services.
    </p>
    <p>Here’s how:</p>
    <b>Provide, personalize and improve Parrly.</b>
    <p>
    We use the information we have to deliver Parrly services, including to personalize features and content and make suggestions for you. To create personalized products and services that are unique and relevant to you, we use your connections, preferences, interests and activities based on the data we collect and learn from you and others; how you use and interact with Parrly; and the people, places, or things you're connected to and interested in on and off Parrly. 
    </p>
    <ul>
        <li>
        <b>
        Location-related information: 
        </b>
        <p>
        We use location-related information-such as your current location, where you live, the places you like to go, and the businesses and people you're near-to provide, personalize and improve Parrly for you and others. Location-related information can be based on things like precise device location (if you've allowed us to collect it), IP addresses, and information from your and others' use of Parrly.
        </p>
        </li>
        <li>
        <b>
        Product research and development: 
        </b>
        <p>
        We use the information we have to develop, test and improve Parrly, including by conducting surveys and research, and testing and troubleshooting new products and features.
        </p>
        </li>
    </ul>
    <b>Promote safety, integrity and security.</b>
    <p>
    We use the information we have to verify accounts and activity, combat harmful conduct, detect and prevent spam and other bad experiences, maintain the integrity of Parrly, and promote safety and security on and off of Parrly. 
    </p>
    <b>Communicate with you.</b>
    <p>
    We use the information we have to send you marketing communications, communicate with you about Parrly, and let you know about our policies and terms. We also use your information to respond to you when you contact us.
    </p>
    <b>Research and innovate for social good.</b>
    <p>
    We use the information we have (including from research partners we collaborate with) to conduct and support research and innovation on topics of general social welfare, technological advancement, public interest, health and well-being. 
    </p>
    <h1><b>III. How is this information shared?</b></h1>
    <p>Your information is shared with others in the following ways:</p>
    <b>People and accounts you share and communicate with</b>
    <p>
    Non-private posts and comments can be seen by anyone, on or off Parrly, including if they don’t have an account. You, other people using Parrly, and we can provide access to or send public information to anyone on or off Parrly, in search results, or through tools and APIs. Public information can also be seen, accessed, reshared or downloaded through third-party services such as search engines, APIs, and offline media such as TV, and by apps, websites and other services that integrate with Parrly.
    </p>
    <b>Content others share or reshare about you</b>
    <p>
    People who can see your activity on Parrly can choose to share it with others on and off Parrly, including people and businesses outside the audience you shared with. For example, when you archive something, they can download, screenshot, or reshare that content to others across or off Parrly, in person or in virtual reality experiences. Also, when you comment on someone else's post or react to their content, your comment or reaction is visible to anyone who can see the other person's content, and that person can change the audience later. 
    </p>
    <b>
    New owner.
    </b>
    <p>
    If the ownership or control of all or part of Parrly or their assets changes, we may transfer your information to the new owner.
    </p>
    <b>
    Sharing with Third-Party Partners
    </b>
    <p>
    We work with third-party partners who help us provide and improve Parrly, which makes it possible to operate our companies and provide free services to people around the world. 
    </p>
    <p>
    Here are the types of third parties we share information with:
    </p>
    <b>Partners who use our analytics services.</b>
    <p>
    We provide aggregated statistics and insights that help people and businesses understand how people are engaging with their content on and off the Parrly. 
    </p>
    <b>Vendors and service providers.</b>
    <p>
    We share information about you with companies that aggregate it to provide analytics and measurement reports to our partners.
    </p>
    <b>Partners offering goods and services in Parrly.</b>
    <p>
    We share information about you with companies that aggregate it to provide analytics and measurement reports to our partners.
    </p>
    <b>Vendors and service providers.</b>
    <p>
    We provide information and content to vendors and service providers who support our business, such as by providing technical infrastructure services, analyzing how Parrly are used, providing customer service, facilitating payments or conducting surveys.
    </p>
    <b>Law enforcement or legal requests.</b>
    <p>
    We share information with law enforcement or in response to legal requests in the circumstances outlined below.
    </p>
    <h1><b>IV. How do we respond to legal requests or prevent harm?</b></h1>
    <p>We access, preserve and share your information with regulators, law enforcement or others: </p>
    <ul>
        <li>
        In response to a legal request (like a search warrant, court order or subpoena) if we have a good faith belief that the law requires us to do so. 
        </li>
        <li>
        When we have a good-faith belief it is necessary to: detect, prevent and address fraud, unauthorized use of Parrly, violations of our terms or policies, or other harmful or illegal activity; to protect ourselves (including our rights and property), you or others, including as part of investigations or regulatory inquiries; or to prevent death or imminent bodily harm. For example, if relevant, we provide information to and receive information from third-party partners about the reliability of your account to prevent fraud, abuse and other harmful activity on and off Parrly.
        </li>
    </ul>
    <p>
    When we have a good-faith belief it is necessary to: detect, prevent and address fraud, unauthorized use of Parrly, violations of our terms or policies, or other harmful or illegal activity; to protect ourselves (including our rights and property), you or others, including as part of investigations or regulatory inquiries; or to prevent death or imminent bodily harm. For example, if relevant, we provide information to and receive information from third-party partners about the reliability of your account to prevent fraud, abuse and other harmful activity on and off Parrly.
    </p>
    <h1><b>V. How will we notify you of changes to this policy?</b></h1>
    <p>
    We may or may not notify you before we make changes to this policy and give you the opportunity to review the revised policy.
    </p>
    </div>
        </div>
    )
}

export default DataPolicy;