import React, { useState, useEffect, useContext } from "react";
import "./donate.css";
import Logo from '../../assets/parrly.png';
import axios from "axios";
import { useLocation, useNavigation } from "react-router-dom";
import { Button, Input } from "antd";
import Header from "../Header/Header";
import constants from "../../constants/constants";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/features/User/UserSlice";
import { AnalyticsContext } from "../../context/context";



const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

const Donate = () => {
  const [profileId, setProfileId] = useState();
  const {state} = useLocation();
  console.log(state);
  const gtagActive = useContext(AnalyticsContext);
  const [message, setMessage] = useState("");
  const [amount, setAmount] = useState(5);
  const [other, setOther] = useState(false);
  const [link, setLink] = useState();
  const user = useSelector(selectUser);
  const handleCheckout = () => {
    let amt;
    if (amount % 5 != 0){
        alert("Invalid amount");
        return;
    }
    if (gtagActive){
      window.gtag('get', 'G-B9XDV07FLM', 'client_id', clientId => {
      axios.post('/api/donate/create-checkout-session', {amount: amount, other: other, customer: user.customerId, profile: state?.profile, email: user.email, GAClientID: clientId})
      .then(res => {
          window.gtag('event', 'donation-created')
          window.open(res.data.link, "_blank")
          setLink(res.data.link);
      }).catch(err => {
          alert(err.response.data);
      })
    })
  } else {
    axios.post('/api/donate/create-checkout-session', {amount: amount, other: other, customer: user.customerId, profile: state?.profile, email: user.email})
    .then(res => {
        window.gtag('event', 'donation-created')
        window.open(res.data.link, "_blank")
        setLink(res.data.link);
    }).catch(err => {
        alert(err.response.data);
    })
  }

}

const ProductDisplay = (props) => 

    (
        <div style={{marginBottom: 100}}>            
            <Header/>
  <section style={{justifyContent: 'center', alignItems: 'center', display: "flex", marginTop: 100}}>
    <div className="donate">
      <img
        src={Logo}
        alt="Donate to Parrly"
      />
      <div className="description">
      <h3>Make a donation to Parrly</h3>
      <div style={{display: 'flex'}}>
        <Button onClick={e => {other && setOther(false);setAmount(5)}} style={{backgroundColor: amount == 5 && constants.purple, color: amount == 5 && "white"}}> $5 </Button>
        <Button onClick={e => {other && setOther(false);setAmount(10)}} style={{backgroundColor: amount == 10 && constants.purple, color: amount == 10 && "white"}}> $10 </Button>
        <Button onClick={e => {other && setOther(false);setAmount(20)}} style={{backgroundColor: amount == 20 && constants.purple, color: amount == 20 && "white"}}> $20 </Button>
        <Button onClick={e => {setAmount(null);setOther(true)}} style={{backgroundColor: other && constants.purple, color: other && "white"}}>Other</Button>
        </div>
      </div>
      <Button style={{background: constants.purple, color: "white", marginTop: "1vh"}} shape="round"  onClick={handleCheckout}>
        Donate
      </Button>
    </div>

  </section>
  </div>
);
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    console.log(query);
    if (query.get("success")) {
      setMessage("Thank you for your donation!");
    setTimeout(() => window.location.href = "/", 2000)

    }

    if (query.get("cancelled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
      window.close();
      setTimeout(() => window.location.href = "/", 2000)
    }
  }, []);

  return message ? (
    <Message message={message} />
  ) : (
    <ProductDisplay />
  );
}
export default Donate;