import { useState } from "react"
import { useNavigate } from "react-router-dom";
import { Turn as Hamburger } from 'hamburger-react';
import CountrySelector from "../components/CountrySelector/CountrySelector";
import { Button, Drawer } from "antd";
import constants from "../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, selectUser } from "../redux/features/User/UserSlice";
import { getCookie } from "../components/LandingPage/LandingPage";
import axios from "axios";
import { LoginOutlined, LogoutOutlined, ProfileOutlined, SettingOutlined } from "@ant-design/icons";


const MenuDesktop = (props) => {
    const [open, setOpen] = useState(false);
    const [country, setCountry] = useState(getCookie('country'));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(selectUser); 
    const Logout = () => {
        axios.post('/api/user/logoutUser', {}, {withCredentials: true, credentials: 'include'})
        .then(res => {
            dispatch(logoutUser(user));
        }).catch(err => console.log(err));
    }
    return (
        <div>
        <Hamburger toggled={open} color={constants.purple} type='link' onClick={() => { setOpen(!open)}}
        onToggle={setOpen}>
            </Hamburger>
            <Drawer bodyStyle={{display: 'flex', flexDirection: 'column', gap: "1vh"}} closable={true} title="Parrly" closeIcon={<Hamburger toggled={open} color={constants.purple} type='link'></Hamburger>} placement="right" onClose={() => setOpen(false)} open={open}>

    
            {user.profileId && <Button icon={<ProfileOutlined/>} onClick={() => navigate(`/profile/${user.webToken}/talk`)}>Profile</Button>}
            {user.state === "loggedIn" && <Button icon={<SettingOutlined/>} onClick={() => navigate('/settings/general')}>Settings</Button>}
            <CountrySelector country={(newCountry) => props.chooseCountry(newCountry)} />
            {user.state === "loggedIn" ?  <Button icon={<LogoutOutlined/>} onClick={Logout}>Logout</Button> :  <Button icon={<LoginOutlined/>} onClick={() => navigate(`/login`)}>Login</Button>}
            </Drawer>
            </div>
    )
}
export default MenuDesktop;