import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Form, Input, Button, Select, Radio } from "antd"
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import constants from "../../constants/constants";
import { selectWards, setWards } from "../../redux/features/Wards/WardSlice";

const SignupVoter = (props) => {
    const [name, setName] = useState();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [age, setAge] = useState();
    const [ward, setWard] = useState();
    const [gender, setGender] = useState();
    const [loading, setLoading] = useState(false)
    const [cities, setCities] = useState([])
    const [city, setCity] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [wards, setWards2] = useState(useSelector(selectWards));
    console.log(wards);
    const handleSubmit = () => {
        setLoading(true);
        const newUser = {
            fullName: name,
            ward: ward,
            age: age,
            email: email,
            password: password,
            gender: gender,
            city: city,
            country: cities.find(citi => citi.value === city).country
        }
        if (!name || !ward || !age || !email || !password || !city || !ward){
            alert("Invalid fields");
            return;
        }
        axios.post('/api/user/signupUser', newUser).then(res => {
            alert(res.data + ` ${email}`);
            navigate('/login');
        }).catch(err => {console.log(err);alert(err.response.data)})
        setLoading(false);
    }
    const handleAge = (e) => {
        let newText = e.target.value.replace(/[^0-9]{1,2}/g, '')
        if(newText.length > 2){
          newText = newText.substring(0,2)
        }
        setAge(newText)
    }
    useEffect(() => {
        axios.get('/api/city/getCities')
        .then(res => {
            let newCities = res.data.map(city => {return {_id: city._id, country: city.country, value: city.city} })
            setCities(newCities);
        })
    }, [])
    const setCityChoice = (city) =>{
        setCity(city)
        setWard(null);
        axios.get(`/api/profile/getWardProfilesByCity?city=${city}`)
        .then(res => {
            let wardsArr;
            if (/\d/.test(res.data[0].fullName)){
                console.log(parseInt(res.data[0].fullName))
                let data = []
                res.data.forEach(ward => {
                    data[parseInt(ward.fullName)] = ward;
                })
                wardsArr = data.map((ward, i) => {return {_id: i, value: ward.ward, description: ward.neighborhood}})
                wardsArr.shift();
            } else {
                wardsArr = res.data.map((ward, i) => {return {_id: i, value: ward.ward, description: ward.neighborhood}})
            }
            dispatch(setWards(wardsArr))
            setWards2(wardsArr);
        })

    }
    return (
        <Form className="ant-card-bordered formVoter" labelCol={{span: 4}} wrapperCol={{span: 14}} layout="horizontal" >
        <Form.Item label="Name" labelAlign="flex-start">
            <Input value={name} onChange={(e) => setName(e.target.value)}/>
        </Form.Item>
        <Form.Item label="Gender">
            <Radio.Group onChange={(e) => setGender(e.target.value)}>
                <Radio.Button value={"Male"}>Male</Radio.Button>
                <Radio.Button value={"Female"}>Female</Radio.Button>
                <Radio.Button value={"Other"}>Other</Radio.Button>
            </Radio.Group>
        </Form.Item>
        <Form.Item label="E-mail">
            <Input value={email} onChange={(e) => setEmail(e.target.value)} suffix={email.includes("@") && email.includes(".") && email.length > 5? <CheckCircleOutlined style={{color: 'green'}}/> :<CloseCircleOutlined style={{color: 'red'}}/>}/>
        </Form.Item>
        <Form.Item label="Password">
            <Input value={password} onChange={(e) => setPassword(e.target.value)} suffix={password.length >= 8 ? <CheckCircleOutlined style={{color: 'green'}}/> : <CloseCircleOutlined style={{color: 'red'}}/>} />
        </Form.Item>
        <Form.Item label="City">
            <Select value={city} onSelect={(ward) => setCityChoice(ward)}>
                {cities.map(wards => <Select.Option value={wards.value} key={wards._id}>{wards.value} - {wards.country}</Select.Option>)}
            </Select>
        </Form.Item>
        {city && <Form.Item label="Ward">
            <Select value={ward} onSelect={(ward) => setWard(ward)}>
                {wards.map(wards => <Select.Option value={wards.value} key={wards._id}>{wards.value}</Select.Option>)}
            </Select>
        </Form.Item>}
        <Form.Item label="Age">
            <Input style={{width: '3rem'}} maxLength={2} value={age} onChange={handleAge}/>
        </Form.Item>
            <Form.Item style={{display:"flex", justifyContent: 'center'}}>
            <Button onClick={handleSubmit} loading={loading} >Submit</Button>

            </Form.Item>
    </Form>
    )
}
export default SignupVoter;