import { DesktopOutlined, FacebookOutlined, InstagramOutlined, TwitterOutlined } from "@ant-design/icons";
import { Avatar, Card, Input, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import constants from "../../constants/constants";
import WindowSize from "../windowSize";

const ProfileCard = ({profile}) => {
    const marginRight = {marginRight: ".25rem"};
    const navigate = useNavigate()
    const [width, height] = WindowSize();
    const mobileView = width <= 700 ? true : false;
    return(
        <Card style={{cursor: 'pointer', borderWidth: 3}} className="profileCard" loading={!profile} title={<div>
            <Avatar size={mobileView ? 50 : 100} src={constants.bucketUrl + profile.profilePicture} /><span style={{marginLeft: "2rem"}}>{profile.fullName}</span></div>} onClick={() => navigate(`/profile/${profile.webToken}/talk`)}>
        {profile.party && <Tag style={{marginBottom: 10}} color={profile.party === "Republican" ? "red" : "blue"}>{profile.party}</Tag>}
        {profile.facebookHandle && <p ><FacebookOutlined style={marginRight} />{profile.facebookHandle}</p>}
        {profile.twitterHandle && <p><TwitterOutlined style={marginRight}/>{profile.twitterHandle}</p>}
        {profile.instagramHandle && <p><InstagramOutlined style={marginRight}/>{profile.instagramHandle}</p>}
        {profile.website && <p><DesktopOutlined style={marginRight}/>{profile.website}</p>}
        </Card>
    )
}
export default ProfileCard;
