import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {Avatar, Input} from 'antd';
import constants from "../../constants/constants";
import { SearchOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import axios from "axios";
import { useSelector } from "react-redux";
import { selectLocation } from "../../redux/features/Location/LocationSlice";
import { getCookie } from "../LandingPage/LandingPage";
import { AnalyticsContext } from "../../context/context";
const SearchBar = (props) => {
    const [searchResults, setSearchResults] = useState([]);
    const [search, setSearch] = useState();
    const [showResults, setShowResults] = useState(false);
    const [country, setCountry] = useState(props.getCountry || getCookie('country'));
    const resultsRef = useRef();
    const searchRef = useRef();
    const wrapperRef = useRef();
    const linkRef = useRef();
    const gtagActive = useContext(AnalyticsContext);
    console.log(country, getCookie('country'), props.getCountry);
    const placeholderText =`Search by ${country === 'CA' ? 'ward' : 'district'} or candidate name`
    const navigate = useNavigate();
    useEffect(() => {
        setSearchResults([]);
        setCountry(props.getCountry);
    }, [props.getCountry])
    useEffect(() => {
        if (search){
            axios.get(`/api/profile/searchProfileLimit?name=${search}&limit=10&country=${getCookie('country')}`)
            .then(profiles => {
                setShowResults(true);
                setSearchResults(profiles.data);
                if (profiles.data.length > 0) {
                    gtagActive && constants.gtagDefined() && window.gtag('event', 'search_results', {'query': search})
                }
            })
            .catch(err=> {
                console.log(err);
                setSearchResults([]);
            })
        }
    }, [search])
    //document.onclick = () => setShowResults(false);
    const handleClick = (e) => {
        let target = e.target.parentElement;
        while (target) {
            if (target === wrapperRef.current || target === linkRef.current){
                setShowResults(true);
                return
            }
            target = target.parentElement;
        }
        setShowResults(false);
    }
    const changeSearch = (text) => {
        setSearch(text.target.value);
    }
    document.addEventListener('mousedown', handleClick);
    return (
        <div className="searchWrapper" ref={wrapperRef}  >
            <div>
            <Input 
            placeholder={placeholderText}
            onPressEnter={() => showResults && searchResults.length > 0 && navigate(`/profile/${searchResults[0].webToken}/talk`) && constants.gtagDefined() && gtagActive && window.gtag('event', 'profile-view', {'name': searchResults[0].fullName})        }
            onFocus={() => setShowResults(true)}
            suffix={<SearchOutlined/>}
            style={ {width: "100%", borderBottomLeftRadius: showResults && searchResults.length > 0 ? 0 : 12, borderBottomRightRadius: showResults && searchResults.length > 0 ? 0 : 12, borderTopRightRadius: 12, borderTopLeftRadius: 12}} 
            onChange={text => changeSearch(text)} 
            type={"search"} ref={searchRef} value={search}
              />
            </div>
            <div  className="searchResults" ref={resultsRef} style={ showResults && searchResults.length > 0 ? {height: "25vh"} : {visibility: 'hidden'}} >
        { showResults && searchResults instanceof Array && searchResults.map(profile => 
        <div ref={linkRef} key={profile._id}>
            <Link onClick={() => {constants.gtagDefined() && gtagActive && window.gtag('event', 'profile-view', {'name': profile.fullName});setShowResults(false)}} 
            id="searchLink" to={`/profile/${profile.webToken}/talk`}>
        <Avatar size={50} style={{marginRight: 20}} src={profile.profilePicture === undefined ? constants.bucketUrl + "default-avatar.png" : constants.bucketUrl + profile.profilePicture}/>
         {profile.fullName}&#09; {(profile.profileType == 'councillor') && `- ${profile.ward}`}</Link> </div>)}
         {!(searchResults instanceof Array) && <p>{searchResults}</p>}
                </div>
        </div>
    )
}

export default SearchBar