import { createSlice } from '@reduxjs/toolkit';

export const locationSlice = createSlice({
    name: 'location',
    initialState: {},
    reducers: {
        setLocation: (state = {}, action) => {
            // const { fullName, email, _id, preference, displayName, verified, following } = action.payload;

            state = action.payload
            // if (fullName) state.fullName = fullName;
            // if (email) state.email = email;
            // if (preference.privacy) state.privacy = preference.privacy;
            // if (displayName) state.displayName = displayName;
            // if (_id) state._id = _id;
            // if (verified) state.verified = verified;
            // if (preference.defaultView) state.defaultView = preference.defaultView;
            // if (following) state.following = following
        },
    }
});


export const {
    setLocation
} = locationSlice.actions;

export const selectLocation = state => state.location;

export default locationSlice.reducer;
