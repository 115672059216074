import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Input, Button} from "antd";
import axios from "axios"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"
import { selectUser } from "../../redux/features/User/UserSlice";




const EmailVerification = () => {

    const {token, id} = useParams();
    const [isValid, setValid] = useState(false);
    const [user, setUser] = useState({});
    const [password, setPassword] = useState('');
    const loginUser = useSelector(selectUser);
    const navigate = useNavigate();
    useEffect(() => {
        axios.get(`/api/user/update-email?token=${token}&id=${id}`)
        .then(res => {
            if (res.data){
                alert("Your e-mail has been verified");
            } else {
                alert("Not a valid e-mail verification request.")
            }
            navigate('/');

        }).catch(err => {
            console.log(err);
            alert(err.response.data || "Not a valid reset password request.")
            navigate('/');
        })
    }, [])
    return (
        <div></div>
    )

}
export default EmailVerification;