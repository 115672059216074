const ipAddr = process.env.REMOTE ? "parrly.com" : "192.168.2.171";
const port = "5000";
const bucketUrl = "https://storage.googleapis.com/elector-p/"
const purple = "#6d028d";
const gtagDefined = () => {
  if (typeof window !== 'undefined'){
    return true
  }
  return false;
}
const wardsArr = [
    { _id: '1', value: 'Etobicoke North' },
    { _id: '2', value: 'Etobicoke Centre' },
    { _id: '3', value: 'Etobicoke-Lakeshore' },
    { _id: '4', value: 'Parkdale-High Park' },
    { _id: '5', value: 'York South-Weston' },
    { _id: '6', value: 'York Centre' },
    { _id: '7', value: 'Humber River-Black Creek' },
    { _id: '8', value: 'Eglinton-Lawrence' },
    { _id: '9', value: 'Davenport' },
    { _id: '10', value: 'Spadina-Fort York' },
    { _id: '11', value: 'University-Rosedale' },
    { _id: '12', value: "Toronto-St. Paul's" },
    { _id: '13', value: 'Toronto Centre' },
    { _id: '14', value: 'Toronto-Danforth' },
    { _id: '15', value: 'Don Valley West' },
    { _id: '16', value: 'Don Valley East' },
    { _id: '17', value: 'Don Valley North' },
    { _id: '18', value: 'Willowdale' },      
    { _id: '19', value: 'Beaches-East York' },
    { _id: '20', value: 'Scarborough Centre' },
    { _id: '21', value: 'Scarborough-Agincourt' },
    { _id: '22', value: 'Scarborough North' },
    { _id: '23', value: 'Scarborough-Guildwood' },
    { _id: '24', value: 'Scarborough-Rouge Park' },
  ]
export default { ipAddr, port, bucketUrl, wardsArr, purple, gtagDefined};
