import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../Header/Header"
import { Card, Avatar} from "antd";
import constants from '../../constants/constants'
import './Elections.css';
import Meta from "antd/lib/card/Meta";
export const handleErr = (e) => {
    console.log(e);
    e.target.src = constants.bucketUrl + "default-avatar.png";
}
const Elections = () => {
    const {id} = useParams();
    const [election, setElection] = useState();
    const [electors, setElectors] = useState([]);
    console.log(election, electors);
    const navigate = useNavigate();
    useEffect(() => {
        axios.get(`/api/profile/getElection?id=${id}`)
        .then(res => {
            setElection(res.data.election);
            setElectors(res.data.electors);
        })
    }, [])
    console.log(election);
    return (
        <div style={{textAlign: 'center'}} className="electionContainer">
            <Header/>
            {election && <h1>{election.name} - {election.incumbent?.ward}</h1>}
            <h1>Incumbent</h1>
            {election && <Card hoverable title={election.incumbent?.fullName} className="electorIncumbent" onClick={ () => navigate(`/profile/${election.incumbent?.webToken}/talk`)} cover={<img onError={handleErr} src={constants.bucketUrl + election.incumbent?.profilePicture || constants.bucketUrl + "default-avatar.png"} />}>
            <Meta 
                        title={(election.incumbent?.country === "CA" ? "Ward: " : "District: ") + election.incumbent?.ward}
                        />
            </Card>}
            <h1>Electors</h1>
            <div className="electorGrid">
                {electors.map(elector => 
                    <Card hoverable onClick={() => navigate(`/profile/${elector.webToken}/talk`)}  className="electorCard" title={elector.fullName} cover={<img onError={e => handleErr(e)} src={constants.bucketUrl + elector.profilePicture}/>}>
                        <Meta 
                        title={(elector.country === "CA" ? "Ward: " : "District: ") + elector.ward}
                        />
                    </Card>
                    )}
            </div>
        </div>
    )
}
export default Elections;
