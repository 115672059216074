import { DownOutlined } from "@ant-design/icons"
import { Button, Dropdown, Image, Space } from "antd"
import { useDispatch, useSelector } from "react-redux";
import { changeCountry, selectUser } from "../../redux/features/User/UserSlice";
import { getCookie } from "../LandingPage/LandingPage";



const CountrySelector = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const items = [
        {
            label: "United States",
            key: "US",
            icon: <Image preview={false} src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg" style={{width: 20, height: 10}}/>
        },
        {
            label: "Canada",
            key: "CA",
            icon: <Image preview={false} src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Canada_%28Pantone%29.svg/1200px-Flag_of_Canada_%28Pantone%29.svg.png" style={{width: 20, height: 10}}/>
        }
      ];
    const onClick = ({ key }) => {
        if (key === "CA"){
            document.cookie = "country=CA;"
            props.country('CA')
        } else {
            document.cookie = "country=US;"
            props.country('US');
        }
      };

    return (
        <Dropdown  menu={{items, onClick}}>
<Button>
<Space>
    {getCookie('country') === "CA" ? items[1].icon : items[0].icon}
  {getCookie('country')}
  <DownOutlined />
</Space>
</Button>
</Dropdown>
    )
}
export default CountrySelector;