import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
const Terms = (props) => {

    useEffect(() => {
        document.title = "Terms of Use - Parrly"
    }, [])

    return (<div>
                    <Header/>


        <div style={{margin:"5em"}}>
            <h1>Terms of Use</h1>
            <p>
                These Terms of Use (or “Terms”) govern your use of Parrly, except where we expressly state that separate terms (and not these) apply, and provide information about the Parrly Service (the “Service”), outlined below. When you create an Parrly account or use Parrly, you agree to these terms. 
The Parrly Service is provided to you by Keyp Technologies Corporation. These Terms of Use therefore constitute an agreement between you and Keyp Technologies Corporation.
            </p>
            <p>
            The Parrly Service is provided to you by Keyp Technologies Corporation. These Terms of Use therefore constitute an agreement between you and Keyp Technologies Corporation.
            </p>
            <h1><b>The Parrly Service</b></h1>
            <p>
            We agree to provide you with the Parrly Service. The Service includes all of the Parrly products, features, applications, services, technologies, and software that we provide. The Service is made up of the following aspects:
            </p>
            <ul>
                <li><b>
                Offering personalized opportunities to create, connect, communicate, discover, and share.
                </b></li>
                <li><b>
                Developing and using technologies that help us consistently serve our growing community.
                </b></li>
                <p>
                Organizing and analyzing information for our growing community is central to our Service. A big part of our Service is creating and using cutting-edge technologies that help us personalize, protect, and improve our Service on an incredibly large scale for a broad global community. Technologies like artificial intelligence and machine learning give us the power to apply complex processes across our Service. Automated technologies also help us ensure the functionality and integrity of our Service.
                </p>
                <li><b>
                Ensuring access to our Service.
                </b></li>
                <p>
                To operate our global Service, we must store and transfer data across our systems around the world, including outside of your country of residence. The use of this global infrastructure is necessary and essential to provide our Service. This infrastructure may be owned or operated by Google LLC or its affiliates.
                </p>
                <li><b>
                Research and innovation.
                </b></li>
                <p>
                We use the information we have to study our Service and collaborate with others on research to make our Service better and contribute to the well-being of our community. 
                </p>
            </ul>

            <h1><b>The Data Policy</b></h1>
            <p>
            Providing our Service requires collecting and using your information. The <Link to='/data-policy'>Data Policy</Link> explains how we collect, use, and share information. You must agree to the Data Policy to use Parrly.
            </p>
            <h1><b>Your Commitments</b></h1>
            <p>
            In return for our commitment to provide the Service, we require you to make the below commitments to us. 
            </p>
            <p>
            <b>Who Can Use Parrly.</b> We want our Service to be as open and inclusive as possible, but we also want it to be safe, secure, and in accordance with the law. So, we need you to commit to a few restrictions in order to be part of the Parrly community. 
            </p>
            <ul>
                <li>
                You must be at least 13 years old or the minimum legal age in your country to use Parrly.
                </li>
                <li>
                You must not be prohibited from receiving any aspect of our Service under applicable laws or engaging in payments related Services if you are on an applicable denied party listing.
                </li>
                <li>
                We must not have previously disabled your account for violation of law or any of our policies.
                </li>
                <li>
                You must not be a convicted sex offender.
                </li>
            </ul>
            <p>
            <b>How You Can't Use Parrly.</b>Providing a safe and open Service for a broad community requires that we all do our part. 
            </p>
            <ul>
                <li>
                <b>You can't impersonate others or provide inaccurate information.</b>
                <p>
                You don't have to disclose your identity on Parrly, but you must provide us with accurate and up to date information (including registration information), which may include providing personal data. Also, you may not impersonate someone or something you aren't, and you can't create an account for someone else unless you have their express permission.
                </p>
                </li>
                <li>
                <b>You can't do anything unlawful, misleading, or fraudulent or for an illegal or unauthorized purpose.</b>
                </li>
                <li>
                <b>
                You can't violate (or help or encourage others to violate) these Terms.
                </b>
                </li>
                <li>
                <b>
                You can't do anything to interfere with or impair the intended operation of the Service.
                </b>
                <p>
                This includes misusing any reporting, dispute, or appeals channel, such as by making fraudulent or groundless reports or appeals.
                </p>
                </li>
                <li>
                <b>
                You can't attempt to create accounts or access or collect information in unauthorized ways.
                </b>
                <p>
                This includes creating accounts or collecting information in an automated way without our express permission.
                </p>
                </li>
                <li>
                <b>
                You can’t sell, license, or purchase any account or data obtained from us or our Service.
                </b>
                <p>
                This includes attempts to buy, sell, or transfer any aspect of your account (including your username); solicit, collect, or use login credentials or badges of other users; or request or collect Parrly usernames, passwords, or misappropriate access tokens.
                </p>
                </li>
                <li>
                <b>
                This includes attempts to buy, sell, or transfer any aspect of your account (including your username); solicit, collect, or use login credentials or badges of other users; or request or collect Parrly usernames, passwords, or misappropriate access tokens.
                </b>
                <p>
                You may use someone else's works under exceptions or limitations to copyright and related rights under applicable law. You represent you own or have obtained all necessary rights to the content you post or share. 
                </p>
                </li>
                <li>
                <b>
                You may use someone else's works under exceptions or limitations to copyright and related rights under applicable law. You represent you own or have obtained all necessary rights to the content you post or share. 
                </b>
                </li>
                <li><b>
                You can't use a domain name or URL in your username without our prior written consent.
                </b></li>
            </ul>

            <p>
            <b>Permissions You Give to Us.</b> As part of our agreement, you also give us permissions that we need to provide the Service. 
            </p>
            <ul>
                <li>
                <b>
                We do not claim ownership of your content, but you grant us a license to use it.
                </b>
                <p>
                Nothing is changing about your rights in your content. We do not claim ownership of your content that you post on or through the Service and you are free to share your content with anyone else, wherever you want. However, we need certain legal permissions from you (known as a “license”) to provide the Service. When you share, post, or upload content that is covered by intellectual property rights (like photos or videos) on or in connection with our Service, you hereby grant to us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to host, use, distribute, modify, run, copy, publicly perform or display, translate, and create derivative works of your content (consistent with your privacy and application settings). 
                </p>
                </li>
                <li>
                <b>
                Permission to use your username, profile picture, and information about your relationships and actions with accounts and sponsored content.
                </b>
                <p>
                Permission to use your username, profile picture, and information about your relationships and actions with accounts and sponsored content.
                </p>
                </li>
                <li><b>
                You agree that we can download and install updates to the Service on your device.
                </b></li>
            </ul>
            <h1><b>Additional Rights We Retain</b></h1>
            <ul>
                <li>
                If you select a username or similar identifier for your account, we may change it if we believe it is appropriate or necessary (for example, if it infringes someone's intellectual property or impersonates another user).
                </li>
                <li>
                If you select a username or similar identifier for your account, we may change it if we believe it is appropriate or necessary (for example, if it infringes someone's intellectual property or impersonates another user).
                </li>
                <li>
                You must obtain written permission from us or under an open source license to modify, create derivative works of, decompile, or otherwise attempt to extract source code from us.
                </li>
            </ul>
            <h1><b>Content Removal and Disabling or Terminating Your Account</b></h1>
            <ul>
                <li>
                We can remove any content or information you share on the Service if we believe that it violates these Terms of Use, our policies, or we are permitted or required to do so by law. We can refuse to provide or stop providing all or part of the Service to you immediately to protect our community or services, or if you create risk or legal exposure for us, violate these Terms of Use or our policies, if you repeatedly infringe other people's intellectual property rights, or where we are permitted or required to do so by law. We can also terminate or change the Service, remove or block content or information shared on our Service, or stop providing all or part of the Service if we determine that doing so is reasonably necessary to avoid or mitigate adverse legal or regulatory impacts on us. 
                </li>
                <li>
                If we disable your account, these Terms shall terminate as an agreement between you and us, but this section and the section below called "Our Agreement and What Happens if We Disagree" will still apply even after your account is terminated, disabled, or deleted.
                </li>
            </ul>
            <h1><b>Our Agreement and What Happens if We Disagree</b></h1>
            <b>Our Agreement.</b>
            <ul>
                <li>
                If any aspect of this agreement is unenforceable, the rest will remain in effect.
                </li>
                <li>
                Any amendment or waiver to our agreement must be in writing and signed by us. If we fail to enforce any aspect of this agreement, it will not be a waiver.
                </li>
                <li>
                We reserve all rights not expressly granted to you.
                </li>
            </ul>
            <b>Who Has Rights Under this Agreement.</b>
            <ul>
                <li>
                This agreement does not give rights to any third parties.
                </li>
                <li>
                You cannot transfer your rights or obligations under this agreement without our consent.
                </li>
                <li>
                Our rights and obligations can be assigned to others. For example, this could occur if our ownership changes (as in a merger, acquisition, or sale of assets) or by law.
                </li>
            </ul>
            <b>Who Is Responsible if Something Happens.</b>
            <ul>
                <li>
                Our Service is provided "as is," and we can't guarantee it will be safe and secure or will work perfectly all the time. TO THE EXTENT PERMITTED BY LAW, WE ALSO DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.
                </li>
                <li>
                We also don’t control what people and others do or say, and we aren’t responsible for their (or your) actions or conduct (whether online or offline) or content (including unlawful or objectionable content). We also aren’t responsible for services and features offered by other people or companies, even if you access them through our Service.
                </li>
                <li>
                We also don’t control what people and others do or say, and we aren’t responsible for their (or your) actions or conduct (whether online or offline) or content (including unlawful or objectionable content). We also aren’t responsible for services and features offered by other people or companies, even if you access them through our Service.
                </li>

            </ul>
            <b>How We Will Handle Disputes.</b>
            <p>
            You agree that any claim, cause of action, or dispute you have against us must be resolved exclusively in the courts in Toronto, Ontario, Canada; that you submit to the personal jurisdiction of the courts for the purpose of litigating any such claim, and that the laws of Canada will govern these Terms and any claim, without regard to conflict of law provisions. Without prejudice to the foregoing, you agree that, in its sole discretion, Keyp Technologies Corporation may also bring any claim we have against you related to efforts to abuse, interfere, or engage with our products in unauthorized ways in the country in which you reside that has jurisdiction over the claim. 
            </p>
            <b>Unsolicited Material.</b>
            <p>
            You agree that any claim, cause of action, or dispute you have against us must be resolved exclusively in the courts in Toronto, Ontario, Canada; that you submit to the personal jurisdiction of the courts for the purpose of litigating any such claim, and that the laws of Canada will govern these Terms and any claim, without regard to conflict of law provisions. Without prejudice to the foregoing, you agree that, in its sole discretion, Keyp Technologies Corporation may also bring any claim we have against you related to efforts to abuse, interfere, or engage with our products in unauthorized ways in the country in which you reside that has jurisdiction over the claim. 
            </p>
            <h1><b>Updating These Terms</b></h1>
            <p>
            We may change our Service and policies, and we may need to make changes to these Terms so that they accurately reflect our Service and policies. Unless otherwise required by law, we may or may not notify you (for example, through our Service) before we make changes to these Terms. If you continue to use the Service, you will be bound by the updated Terms.
            </p>
            </div>
        </div>
    )
}

export default Terms;