import React, { useEffect, useState, useLayoutEffect, useContext } from 'react';
import './LandingPage.css';
import { Link, useNavigate } from 'react-router-dom';
import SearchBar from '../Input/SearchBar'
import Search from '../Input/Search'
import { Avatar, Button, Drawer, Dropdown, Menu, message, Space} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { changeCountry, logoutUser, selectUser, setUser } from '../../redux/features/User/UserSlice';
import constants from '../../constants/constants';
import WindowSize from '../windowSize';
import { ConsoleSqlOutlined, DownOutlined, UserAddOutlined, UserDeleteOutlined, UserOutlined } from '@ant-design/icons';
import CountrySelector from '../CountrySelector/CountrySelector';
import { handleErr } from '../Elections/Elections';
import { MobileMenu } from '../Header/Header';
import { Turn as Hamburger } from 'hamburger-react';
import Parrly from '../../assets/parrly.png'
import MenuDesktop from '../../Menu/Menu';
import { AnalyticsContext } from '../../context/context';

    export function getCookie(name) {
        const value = `;${document.cookie}`
        const parts = value.split(`;${name}=`)
        const parts2 = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop().split(';').shift()
        else if (parts2.length === 2) return parts2.pop().split(';').shift();
        else return ''
      }
const LandingPage = () => {
    const [width, height] = WindowSize();
    const gtagActive = useContext(AnalyticsContext);
    const navigate = useNavigate();
    const [randomProfiles, setRandomProfiles] = useState([]);
    const [open, setOpen] = useState(false);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    let mobileView = false
    if (width < 740){
        mobileView = true;
    }
    const [navOpen, setNavOpen] = useState(false);

    const [country, setCountry] = useState(getCookie('country'));
      useEffect(() => {

        if (!user._id && getCookie('jwt')){
            axios.get('/api/user/loginValidate', {withCredentials: true, credentials: 'include'})
            .then(res => {
                dispatch(setUser(res.data))
                axios.get(`/api/profile/randomProfiles?country=${country}`)
                .then(res2 => {
                    setRandomProfiles(res2.data);
                }).catch(err => console.log(err));
            })
            .catch(err => console.log(err));
        }

    }, [])
    useEffect(() => {
        if (country){
            axios.get(`/api/profile/randomProfiles?country=${country}`)
            .then(res => {
                setRandomProfiles(res.data);
            }).catch(err => console.log(err));
        } else {
            axios.get(`/api/user/getIP`)
            .then(res => {
                if (res.data.country === 'CA' || res.data.country === 'US'){
                    document.cookie = `country=${res.data.country}`
                    setCountry(res.data.country);
                }
                 else {
                    document.cookie = `country=CA`
                    setCountry('CA');
                 }
            })
            .catch(err => {
                console.log(err);
                setCountry('CA');
                document.cookie = `country=CA`;
            })
        }
    }, [country])
    console.log(gtagActive)
    const handleToggle = () => {
        setNavOpen(!navOpen);
    }
    return (
        !mobileView ? 
        //Desktop View
        <div className="landingPage">
            <div className='accountButtons'>

            {user.state === "loggedIn" ? <Button shape='round' onClick={() => {
            axios.post('/api/user/logoutUser', {}, {withCredentials: true, credentials: 'include'})
            .then(res => {
                dispatch(logoutUser(user));
            }).catch(err => console.log(err));
        }}>Logout</Button> : <Button shape='round' onClick={() => navigate('/login')}>Login</Button>}
                <MenuDesktop chooseCountry={country => setCountry(country)}/>
                </div>
            <div className="searchAreaLandingPage">
                <div className="searchAreaLogo">
                    <img width='65%' src={require('../../assets/parrly.png')} />
                </div>
                <div>Discuss local politics.<br/><br/></div>
                <div className="landingPageSearchInput">
                    <SearchBar/>
                </div>
                <div className='profilePreview'>
                {randomProfiles.map(profile => <div key={profile._id}><Avatar style={{cursor: 'pointer'}} onClick={() => navigate(`/profile/${profile.webToken}/talk`)} src={constants.bucketUrl + profile.profilePicture} size={75}/><p style={{textAlign: 'center'}}>{profile.fullName}</p></div>)}
                </div>

            </div>
            <div style={{position: 'absolute', width:"100vw", bottom: 0, display: 'flex', justifyContent: 'center', marginBottom: 20, gap: "1vh"}}>
                <Link to='/data-policy'>Data Policy</Link>
                <Link to='/terms'>Terms</Link>
                </div>
        </div> 
        :
        //Mobile View
        <div>
                                        <MobileMenu chooseCountry={(country) => setCountry(country)} />
        <div className="landingPage">

        <div className="searchAreaLandingPage">
            <div className="searchAreaLogo">
                <img width='65%' src={require('../../assets/parrly.png')} />
            </div>
            <div>Find out what's going on in your {country === 'CA' ? 'ward' : 'district'}<br/><br/></div>
            <div className="landingPageSearchInput">
                <Search/>
            </div>
            <div className='profilePreview'>
                {randomProfiles.map(profile => <div style={{}} key={profile._id}><Avatar style={{cursor: 'pointer'}} onClick={() => navigate(`/profile/${profile.webToken}/talk`)} src={constants.bucketUrl + profile.profilePicture} size={50}/><p style={{textAlign: 'center', wordWrap: 'break-word', fontSize: 12}}>{profile.fullName}</p></div>)}
            </div>
        </div>
        <div style={{position: 'absolute', width:"100vw", bottom: 0, display: 'flex', justifyContent: 'center', marginBottom: 20, gap: "1vh"}}>
                <Link to='/data-policy'>Data Policy</Link>
                <Link to='/terms'>Terms</Link>
                </div>
    </div>
    </div>

    )
}

export default LandingPage
