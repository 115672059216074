

import React, { useEffect } from 'react';
import './Login.css';
import LoginModal from './LoginModal'
import { useSelector } from 'react-redux';
import {selectUser} from '../../redux/features/User/UserSlice'
import { useNavigate } from 'react-router';
import Header from "../Header/Header";



const Login = (props) => {

    const navigate = useNavigate()
    const user = useSelector(selectUser)
    useEffect(() => {
        document.title = "Login - Parrly"

        if(user._id){
            navigate(-1)
        }
    })




    return (
        <div>
            <Header/>
        <div className="login">
            <div className="loginModal">
                <LoginModal props={props}/>   
            </div>
        </div>
        </div>
        
    )
}

export default Login

