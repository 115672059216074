import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Select, Slider, Switch, Modal} from "antd";
import FormItemLabel from "antd/lib/form/FormItemLabel";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import constants from "../../constants/constants";
import { selectUser, setUser } from "../../redux/features/User/UserSlice"
import { selectWards, setWards } from "../../redux/features/Wards/WardSlice";
import Header from "../Header/Header"
import { getCookie } from "../LandingPage/LandingPage";
import './Settings.css';


const Settings = () => {
    const user = useSelector(selectUser); 
    const [email, setEmail] = useState(user.email);
    const [ward, setWard] = useState(user.ward);
    const [wards, setWards2] = useState(useSelector(selectWards));
    const [newUser, setNewUser] = useState(user);
    const [cities, setCities] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    console.log(user);
    useEffect(() => {
        if (!user._id && getCookie('jwt')){
            axios.get('/api/user/loginValidate', {withCredentials: true, credentials: 'include'})
            .then(res => {
                if (!res.data){
                    navigate('/');
                } else {
                    console.log("RESDATA", res.data);
                    dispatch(setUser(res.data))
                    setNewUser(res.data);
                }

            })
            .catch(err => console.log(err));
        }
        getCities();
    }, [])
    useEffect(() => {
        setNewUser(user);
    }, [user])
    const getCities = () => {
        axios.get('/api/city/getCities')
        .then(res => {
            let newCities = res.data.map(city => {return {_id: city._id, country: city.country, value: city.city} })
            setCities(newCities);
        })
    }
    useEffect(() => {
        console.log(newUser.city, user.city);
        if (newUser.city !== user.city){
            axios.get(`/api/profile/getWardProfilesByCity?city=${newUser.city}`)
            .then(res => {
                let wardsArr;
                if (/\d/.test(res.data[0].fullName)){
                  let data = []
                  res.data.forEach(ward => {
                      data[parseInt(ward.fullName)] = ward;
                  })
                  wardsArr = data.map((ward, i) => {return {_id: i, value: ward.ward, description: ward.neighborhood}})
                  wardsArr.shift();
              } else {
                  wardsArr = res.data.map((ward, i) => {return {_id: i, value: ward.ward, description: ward.neighborhood}})
              }                   
              dispatch(setWards(wardsArr))
                setWards2(wardsArr);
                setNewUser({...newUser, ward: null})
            })
        } else {
            axios.get(`/api/profile/getWardProfilesByCity?city=${newUser.city}`)
            .then(res => {
                let wardsArr;
                if (/\d/.test(res.data[0].fullName)){
                  let data = []
                  res.data.forEach(ward => {
                      data[parseInt(ward.fullName)] = ward;
                  })
                  wardsArr = data.map((ward, i) => {return {_id: i, value: ward.ward, description: ward.neighborhood}})
                  wardsArr.shift();
              } else {
                  wardsArr = res.data.map((ward, i) => {return {_id: i, value: ward.ward, description: ward.neighborhood}})
              }                
              dispatch(setWards(wardsArr))
                setWards2(wardsArr);
            })
        }
    }, [newUser.city])

    const checkUsers = () => {
        const newUser2 = {...newUser}
        const user2 = {...user}
        delete newUser2.emailNotifs
        delete newUser2.pushNotifs
        delete newUser2.mayorUpdates
        delete user2.emailNotifs
        delete user2.pushNotifs
        delete user2.mayorUpdates
        for (const key in newUser2) {
            if (user2[key] !== newUser2[key]){
                return false;
            }
        }
        return true;
    }
    const handleEmailNotif = (val, key) => {
        const newEmailNotifs = {...newUser.emailNotifs, [key]: val};
        axios.post(`/api/user/updateEmailNotifications?token=${user.token}`, newEmailNotifs)
        .then(res => {
          dispatch(setUser({...user, emailNotifs: newEmailNotifs}))
          setNewUser({...newUser, emailNotifs: newEmailNotifs});
        }).catch(err => message.error("Unable to change notifications"));
      }
      const handlePushNotif = (val, key) => {
        const newPushNotifs = {...newUser.pushNotifs, [key]: val};
        axios.post(`/api/user/updatePushNotifications?token=${user.token}`, newPushNotifs)
        .then(res => {
          setNewUser({...newUser, pushNotifs: newPushNotifs});
          dispatch(setUser({...user, pushNotifs: newPushNotifs}))
        }).catch(err => message.error("Unable to change notifications"));
      }
      const handleMayorUpdates = (val) => {
        if (user.mayorUpdates !== val){
            axios.post(`/api/user/updateUserJWT?token=${user.token}`, {mayorUpdates: val})
            .then(res => {
                setNewUser({...newUser, mayorUpdates: val});
                dispatch(setUser({...user, mayorUpdates: val}));
            })
            }
        }    
    const saveNewUser = async () => {
        if (newUser.email.length <= 5 || !newUser.email.includes(".") || !newUser.email.includes("@")){
            message.info("Invalid e-mail");
            return;
        }
        if (!newUser.ward){
            message.info("Need to select a ward");
            return;
        }
        if (newUser.email !== user.email){
            const ok = await Modal.confirm({
                title: "You are trying to change your e-mail. This requires verification, do you wish to proceed?",
                onOk: async () => {
                    const verifEmail = await axios.post(`/api/user/sendVerifEmailUpdate?token=${user.token}`, {email: newUser.email})
                    if (verifEmail.status == 200){
                        const {email, ...rest} = newUser
                        const res = await axios.post(`/api/user/updateUserJWT?token=${user.token}`, rest, {withCredentials: true, credentials: 'include'})
                        if (res.data){
                            dispatch(setUser(rest));
                            if (newUser.country !== user.country){
                                document.cookie = `country=${newUser.country}`
                            }
                            message.success(`Success. An e-mail has been sent to ${newUser.email}. Your account has been updated. Your e-mail will not change until it's verified`);
                        } else {
                            message.error("Error occurred");
                        }
                    } else {
                        message.error("Error occurred");
                    }
                },
                onCancel: async () => {
                    const {email, ...rest} = newUser
                    try {
                        const res = await axios.post(`/api/user/updateUserJWT?token=${user.token}`, rest, {withCredentials: true, credentials: 'include'})
                        message.success("Updated account except e-mail");
                        if (newUser.country !== user.country){
                            document.cookie = `country=${newUser.country}`
                        }
                        dispatch(setUser(rest));
                    }catch(e) {
                        message.error("An error has occurred");
                    }
                }
            });
        } else {
                axios.post(`/api/user/updateUserJWT?token=${user.token}`, newUser, {withCredentials: true, credentials: 'include'})
                .then(res => {
                    if (newUser.country !== user.country){
                        document.cookie = `country=${newUser.country}`
                    }
                    message.success("Successfully saved info");
                    const {password, ...updatedUser} = newUser;
                    dispatch(setUser(updatedUser));
                }).catch(err => {
                    console.log(err);
                    message.error("An error has occurred");
                })
        }
    }
    //TODO Maybe allowing changing city?
    return (
        <div>
            <Header/>
        <div className="settings ant-card-bordered">
        <div className="sideBar">
            <h1 onClick={() => navigate(`/settings/general`)} style={{color: constants.purple}}>General</h1>
            <h1 onClick={() => navigate(`/settings/security`)}>Security</h1>
        </div>
        
        <Form>
            <Form.Item>
                Name 
                <Input  onChange={e => setNewUser({...newUser, fullName: e.target.value})} value={newUser.fullName}/>
            </Form.Item>
            <Form.Item > 
                E-mail
            <Input onChange={(e) => setNewUser({...newUser, email: e.target.value})} value={newUser.email} allowClear={true} suffix={newUser.email?.includes("@") && newUser.email?.includes(".") && newUser.email.length > 5? <CheckCircleOutlined style={{color: 'green'}}/> :<CloseCircleOutlined style={{color: 'red'}}/>}/>
        </Form.Item>
        <Form.Item>
            City
        <Select value={newUser.city} onSelect={(city) => {let newCity = cities.find(e => e.value === city); setNewUser({...newUser, city: newCity.value, country: newCity.country})}}>
                {cities.map(city => <Select.Option value={city.value} key={city._id}>{city.value} - {city.country}</Select.Option>)}
            </Select>
        </Form.Item>
        {newUser.city && <Form.Item>
            {newUser.country === "CA" ? "Ward" : "District"}
            <Select value={newUser.ward} onSelect={(ward) => setNewUser({...newUser, ward: ward})}>
                {wards.map(wards => <Select.Option value={wards.value} key={wards._id}>{wards.value}</Select.Option>)}
            </Select>
        </Form.Item>}
        <Form.Item>
       <p><strong>E-mail Notifications:</strong></p>
       
       <p>New comments on post<Switch checked={newUser.emailNotifs?.newCommentOnPost} onChange={val => handleEmailNotif(val, 'newCommentOnPost')}/></p>
       <p>New Post<Switch checked={newUser.emailNotifs?.newPost} onChange={val => handleEmailNotif(val, 'newPost')}/></p>
       <p>Replies on comments<Switch checked={newUser.emailNotifs?.replyOnComment} onChange={val => handleEmailNotif(val, 'replyOnComment')}/></p>
        <p><strong>Push Notifications:</strong></p>
        <p>New comments on post<Switch checked={newUser.pushNotifs?.newCommentOnPost} onChange={val => handlePushNotif(val, 'newCommentOnPost')}/></p>
       <p>New Post<Switch checked={newUser.pushNotifs?.newPost} onChange={val => handlePushNotif(val, 'newPost')}/></p>
       <p>Replies on comments<Switch checked={newUser.pushNotifs?.replyOnComment} onChange={val => handlePushNotif(val, 'replyOnComment')}/></p>
       <p>Mayoral Updates<Switch checked={newUser.mayorUpdates} onChange={val => handleMayorUpdates(val)}/></p>
        </Form.Item>
        {!checkUsers() && <div className="settingsButtons"><Button style={{backgroundColor: constants.purple, color: 'white', borderColor: 'black', borderWidth: 1}} onClick={saveNewUser}>Save changes</Button><Button onClick={() => {setNewUser(user);getCities()}}>Discard changes</Button></div>}
        </Form>
        </div>
        </div>
    )
}
export default Settings;