import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Input, Button} from "antd";
import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"
import constants from "../../constants/constants";
import { AnalyticsContext } from "../../context/context";
import { selectUser } from "../../redux/features/User/UserSlice";
import './Login.css';




const ResetPassword = () => {

    const {token, id} = useParams();
    const [isValid, setValid] = useState(false);
    const [user, setUser] = useState({});
    const [password, setPassword] = useState('');
    const loginUser = useSelector(selectUser);
    const gtagActive = useContext(AnalyticsContext);
    const navigate = useNavigate();
    const handleSubmit = () => {
        if (password.length >= 8){
            user.password = password;
        } else {
            alert("Password is not valid, must be 8 characters or more");
            return;
        }
        axios.post('/api/user/setForgotPassword', {user: user, token: token})
        .then(res => {
            alert("Successfully changed password");
            navigate("/login");
            constants.gtagDefined() && gtagActive && window.gtag('event', 'set-forgot-password', {'name': user.fullName})
        }).catch(err => {
            console.log(err);
            alert("Error occurred while resetting password");
        })
    }
    useEffect(() => {
        if (loginUser._id){
            navigate('/');
            return;
        }
        axios.get(`/api/user/reset-password?token=${token}&id=${id}`)
        .then(res => {
            if (res.data){
                setUser(res.data.userId)
                setValid(true);
            } else {
                alert("Not a valid reset password request.")
                navigate('/');
            }

        }).catch(err => {
            console.log(err);
            alert(err.response.data || "Not a valid reset password request.")
            navigate('/');
        })
    }, [])
    return (
        isValid ?
        <div className="resetPass">
        <p>Enter your new password</p>
        <Input style={{width: "30%"}} minLength={8} value={password} onChange={(text) => setPassword(text.target.value)} suffix={password.length >= 8 ? <CheckCircleOutlined style={{color: 'green'}}/> : <CloseCircleOutlined style={{color: 'red'}}/>}/>
        <Button onClick={handleSubmit}>Submit</Button>
        </div>
        : <div></div>
    )

}
export default ResetPassword;