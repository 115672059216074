import { useEffect, useState, useRef,useLayoutEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import './Profile.css'
import ProfileHeader from "./ProfileHeader";
import ProfilePostCard from './ProfilePostCard';
import Header from "../Header/Header";
import Modal from "./Modal";
import { LoadingOutlined, ProfileOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "../../redux/features/User/UserSlice";
import CreatePost from "./CreatePost";
import { getCookie } from "../LandingPage/LandingPage";
import WindowSize from "../windowSize";
import constants from "../../constants/constants";
import { Spin } from "antd";
import ProfileCard from "./ProfileCard";
const Profile = (props) => {
    const [width, height] = WindowSize();
    const [tab, setTab] = useState(props.tab);
    const [profile, setProfile] = useState({});
    const [posts, setPosts] = useState([]);
    const [noMorePosts, setNoMorePosts] = useState(false);
    const [profiles, setProfiles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const [id, setId] = useState(profile._id || null);
    const containerRef = useRef();
    const [createPost , setCreatePost] = useState(false);
    const navigate = useNavigate();
    let {token} = useParams();
    const location = useLocation();
    const modalRef = useRef();
    let URL = location.pathname.split("/").slice(-1)[0];
    let postId = null;
    let commentId =  null;
    if (location.search){
        if (location.search.includes("post")){
            postId = location.search.split("?")[1].split("=")[1]
        }
        if (location.search.includes("comment")){
            commentId = location.search.split("?")[2].split("=")[1];
        }
    }
    let mobileView = false;

    if (width <= 740){
        mobileView = true;
   }
    let profileType = profile.profileType;
    console.log(profiles, 'profiles');
    const getProfile = () => {
        if (profile.webToken == token && profile._id){
            setId(profile._id)
            getPosts();
            return;
        }
        axios.get(`/api/profile/getProfileByWebToken?token=${token}`)
        .then(res => {
            setProfile(res.data)
            setId(res.data._id);
            profileType = res.data.profileType
            if (res.data.profileType === "ward"){
                getWardPosts(res.data);
            } else {
                if (URL == 'talk'){
                    getProfilePostsPopulate({typePost: 'Post', skip: 0, id: res.data._id})
                } else if (URL == 'qna'){
                    getProfilePostsPopulate({typePost: 'QnA', skip: 0, id: res.data._id})
                } else if (URL == "news"){
                    getProfilePostsPopulate({typePost: 'News', skip: 0, id: res.data._id})
                } 
            }
        })
        .catch(err => {console.log(err);alert("Error retrieving profile")});
      }
    const addNewPost = (post) => {
        let newPosts = [...posts];
        newPosts.unshift(post);
        setPosts(newPosts);
    }
    const getFollowerPosts = () => {
        const profileType = user.mayorUpdates == true ? "mayor,councillor" : "councillor"
        axios.get(`/api/post/getFeedPosts?userId=${user._id}&profileType=${profileType}`)
        .then(res => {
            setPosts(res.data);
            console.log('AYO22e12e');

          if (res.data.length < 1){
            console.log('AYO');
            getWardProfilesPost();
          }
        })
      }
    const getPosts = () => {
        if (profile.profileType !== "ward"){
          if (props.tab == 0){
            getProfilePostsPopulate({typePost:"Post", skip:0});
          } else if (props.tab == 1){
            getProfilePostsPopulate({typePost:"QnA", skip: 0});
          } else if (props.tab == 2){
           getProfilePostsPopulate({typePost:"News", skip: 0});
          } 
        } else {
          getWardPosts();
        }
      }
      const getWardProfilesPost = () => {
        axios.get(`/api/profile/getProfilesByWard?ward=${user.ward}`)
        .then(res => {
            console.log("RES", res);
            setProfiles(res.data)})
        .catch(err => console.log(err));
      }
    const getProfilePostsPopulate = (query) => {
        let typePost = query.typePost
        let skip = query.skip ? query.skip : 0 
        let id2 = query.id ? query.id : id || profile._id;
        axios.get(`/api/post/getPostsPopulate?id=${id2}&type=${typePost}&skip=${skip}`)
        .then((res) => {
            if (res.data.length > 0 && postId){
                let ind = res.data.findIndex(post => post._id === postId);
                let firstPost = res.data[ind];
                res.data[ind] = res.data[0];
                res.data[0] = firstPost;
            }
            setPosts(res.data);
                /*if(typePost == "Post"){
                     setPosts(res.data);
                } else if(typePost == 'QnA'){
                    setqnaPosts(res.data)
                } else {
                    setNewsPosts(res.data)
                }*/
        })
        .catch(err => console.log(err))
    }
  const getWardPosts = (profile) => {
    
    axios.get(`/api/profile/getWardProfiles?ward=${profile.ward}&profileType=councillor`)
    .then(res => {
        if (res.data.length > 0 && postId){
            let ind = res.data.findIndex(post => post._id === postId);
            let firstPost = res.data[ind];
            res.data[ind] = res.data[0];
            res.data[0] = firstPost;
        }
        setPosts(res.data);
    }).catch(err => console.log(err));
  }
  const getWardPostsSkip = () => {
    axios.get(`/api/profile/getWardProfiles?ward=${profile.ward}&profileType=councillor&skip=${posts.length}`)
    .then(res => {
        if (res.data.length === 0){
            setNoMorePosts(true);
            setLoading(false);
        }else {
            setPosts(posts.concat(res.data));
            setLoading(false);
        }
    }).catch(err => {console.log(err)
    setLoading(false);
    });
  }
  const getNextPosts = () => {
    const now = Date.now();
    if (posts.length == 0){
        return;
    }
    axios.get(`/api/post/getPostsPopulate?id=${id}&skip=${posts.length}&type=${posts[0].typePost}`)
    .then(res => {

        if (res.data.length === 0 || !res.data){
            setNoMorePosts(true);
        } else {
            setPosts(posts.concat(res.data))
        }
    })
}
    useEffect(() => {
        setTab(props.tab);
        getProfile();
        setNoMorePosts(false);
        setCreatePost(false);
    }, [token, URL, user])
    useEffect(() => {
        if (profileType == 'ward' && (location.pathname.includes("qna") || location.pathname.includes("news"))){
            let loc = location.pathname.split('/').slice(0, 3);
            loc.push('talk');

            navigate(loc.join('/'));
        }
    }, [token])
    useEffect(() => {
        if (!user._id && getCookie('jwt')){
            axios.get('/api/user/loginValidate', {withCredentials: true, credentials: 'include'})
            .then(res => {
                dispatch(setUser(res.data))
            })
            .catch(err => console.log(err));
        }
    }, [])
    const handleScroll = (e) => {
        const target = e.target.documentElement;
        if ((target.scrollHeight - target.scrollTop <= target.clientHeight)){
            if (profileType === "ward" && !noMorePosts){
                setLoading(true);
                getWardPostsSkip();
            }else if (!noMorePosts){
                setLoading(true);
                getNextPosts();
            }
        }
        setLoading(false);
    }
    const modalClick = (e) => {
        let target = e.target;
            if (target == modalRef.current){
                setShowModal(false);
            } 
    }
    const showWardPosts = () => {
        return (
            <div>

            </div>

        )
    }
     document.body.onscroll = handleScroll
     console.log(profile, 'profile')
     console.log(posts, 'posts');
    return (
        !mobileView ? 
        showModal ?
        <div className="profileContainer" ref={containerRef} onScroll={(e) => handleScroll(e)}>
        <Modal modalRef={modalRef} onClick={modalClick}/>{/*
            <Header/>
            <div className="profileWrapper">
                <div className="sideBar">
                {profileType === "ward" ? <h1 style= {{color: 'blue'}}>Feed</h1> : 
                <div>
                    <h1 onClick={() => navigate(`/profile/${token}/talk`)} style={tab == 0 ? {color: 'blue'} : {}}>Talk</h1>
                    <h1 onClick={() => navigate(`/profile/${token}/qna`)} style={tab == 1 ? {color: 'blue'} : {}}>Q&A</h1>
                    <h1 onClick={() => navigate(`/profile/${token}/news`)} style={tab == 2 ? {color: 'blue'} : {}}>News</h1>
                </div>
                }
                </div>
                <div className="profile">
                    <ProfileHeader tab={tab} profile={profile}/>
                    {tab == 0 && posts.map(post => <ProfilePostCard key={post._id} post={post}/>)}
                    {posts.length == 0 && tab == 0 && "No posts yet..."}
                    {tab == 1 && qnaPosts.map(post => <ProfilePostCard key={post._id} post={post}/>)}
                    {qnaPosts.length == 0 && tab == 1 && "No QnAs yet..."}
                    {tab == 2 && newsPosts.map(post => <ProfilePostCard key={post._id} post={post}/>)}
                    {newsPosts.length == 0 && tab == 2 && "No news yet..."}
                </div>
            </div>*/}
        </div>
        :
        //Desktop View
        <div className="profileContainer" ref={containerRef} onScroll={(e) => handleScroll(e)}>
        <Header />
        <div className="profileWrapper">
            <div className="sideBar">
            {profileType === "ward" ? <h1 style={{color: constants.purple}}>Feed</h1> : 
            user.state === "loggedIn" && <h1 onClick={() => navigate(`/myfeed`)} style={tab == 3 ? {color: constants.purple} : {}}>Feed</h1>
            }
            </div>
            <div className="profile">
                 <ProfileHeader  addNewPost={post => addNewPost(post)} mobileView={mobileView} tab={tab} showModal={() => setShowModal(true)} profile={profile}/>
                 <div className="profileTabs">
                 <h1 onClick={() => navigate(`/profile/${token}/talk`)} style={tab == 0 ? {color: constants.purple} : {}}>Talk</h1>
                    <h1 onClick={() => navigate(`/profile/${token}/qna`)} style={tab == 1 ? {color: constants.purple} : {}}>Q&A</h1>
                    <h1 onClick={() => navigate(`/profile/${token}/news`)} style={tab == 2 ? {color: constants.purple} : {}}>News</h1>
                 </div>
                {
                posts.length < 1 && tab == 3 ? 
                profiles.map(profile => <ProfileCard profile={profile} key={profile._id}/>)
                :
                
                posts.map(post => <ProfilePostCard profile={profile} style={post._id === postId ? {borderWidth: 1, borderColor: constants.purple} : null} commentId={post._id === postId ? commentId : null} callback={() => setShowModal(true)} key={post._id} post={post}/>)}
                {posts.length == 0 && tab == 0 && "No posts yet..."}
                {posts.length == 0 && tab == 1 && "No QnAs yet..."}
                {posts.length == 0 && tab == 2 && "No news yet..."}
                {posts.length == 0 && tab == 3 && "Got anything to say about your local politicians?" && showWardPosts()}
                <Spin spinning={loading} size={'large'} tip="Retrieving more content..." indicator={<LoadingOutlined/>}/>
            </div>
        </div>
    </div>
        : 
        <div>
        {showModal ? 
            <div>      
            <Modal modalRef={modalRef} onClick={modalClick} style={{width: "50%"}}/>{/*
        <div className="profileContainer" ref={containerRef} onScroll={(e) => handleScroll(e)}>
            <Header />
            <div className="profileWrapper">
                <div className="profile">
                    <ProfileHeader tab={tab} profile={profile}/>
                    {tab == 0 && posts.map(post => <ProfilePostCard callback={() =>setShowModal(true)} key={post._id} post={post}/>)}
                    {posts.length == 0 && tab == 0 && "No posts yet..."}
                    {tab == 1 && qnaPosts.map(post => <ProfilePostCard callback={() =>  setShowModal(true)} key={post._id} post={post}/>)}
                    {qnaPosts.length == 0 && tab == 1 && "No QnAs yet..."}
                    {tab == 2 && newsPosts.map(post => <ProfilePostCard callback={() => setShowModal(true)} key={post._id} post={post}/>)}
                    {newsPosts.length == 0 && tab == 2 && "No news yet..."}
                </div>
            </div>
        </div>
        {profileType !== 'ward' && 
        <div className="bottomTabs">
                    <h1 onClick={() => navigate(`/profile/${token}/talk`)} style={tab == 0 ? {color: 'blue'} : {}}>Talk</h1>
                    <h1 onClick={() => navigate(`/profile/${token}/qna`)} style={tab == 1 ? {color: 'blue'} : {}}>Q&A</h1>
                    <h1 onClick={() => navigate(`/profile/${token}/news`)} style={tab == 2 ? {color: 'blue'} : {}}>News</h1>
        </div>}*/}
        </div>  
         :  
         //Mobile view
        <div>       
        <div className="profileContainer" ref={containerRef} onScroll={(e) => handleScroll(e)}>
            <Header />
            <div className="profileWrapper">
                <div className="profile" style={{width: "100%"}}>
                    <ProfileHeader addNewPost={post => addNewPost(post)} showModal={() => setShowModal(true)} mobileView={mobileView} tab={tab} profile={profile}/>
                    {
                posts.length < 1 && tab == 3 ? 
                profiles.map(profile => <ProfileCard profile={profile} key={profile._id} />)
                :
                
                posts.map(post => <ProfilePostCard profile={profile} style={post._id === postId ? {borderWidth: 1, borderColor: constants.purple} : null} commentId={post._id === postId ? commentId : null} callback={() => setShowModal(true)} key={post._id} post={post}/>)}                    {posts.length == 0 && tab == 0 && "No posts yet..."}
                    {posts.length == 0 && tab == 1 && "No QnAs yet..."}
                    {posts.length == 0 && tab == 2 && "No news yet..."}
                    {loading && <LoadingOutlined size={100}/>}
                </div>
            </div>
        </div>
        {profileType !== 'ward' && 
        <div className="bottomTabs">
            {user.state === "loggedIn" && <h1 onClick={() => navigate(`/myfeed`)} style={ tab == 3 ? {color: 'lightgrey'} : {}}>Feed</h1>}
                    <h1 onClick={() => navigate(`/profile/${token}/talk`)} style={tab == 0 ? {color: 'lightgrey'} : {}}>Talk</h1>
                    <h1 onClick={() => navigate(`/profile/${token}/qna`)} style={tab == 1 ? {color: 'lightgrey'} : {}}>Q&A</h1>
                    <h1 onClick={() => navigate(`/profile/${token}/news`)} style={tab == 2 ? {color: 'lightgrey'} : {}}>News</h1>
        </div>}

        </div>  
        }
        </div>
    )
}

export default Profile;
