import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Avatar } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import constants from '../../constants/constants';
import './MobileSearch.css';
import { useSelector } from 'react-redux';
import { getCookie } from '../LandingPage/LandingPage';
import { AnalyticsContext } from '../../context/context';
const Search = ({onFocus}) => {
    const gtagActive = useContext(AnalyticsContext);
    const navigate = useNavigate();
    const [mobileSearchActive, setMobileSearchActive] = useState(false);
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const location = getCookie('country');
    console.log(location, 'location', document.cookie);
    const placeholderText =`Search by ${location === 'CA' ? 'ward' : 'district'} or candidate name`
    const onHide = () => {
        setMobileSearchActive(false);
    }

    const handleChange = (input) => {
        setSearch(input.target.value);
    }
    const handleSearch = (text) => {
        mobileSearchActive && searchResults.length > 0 && navigate(`/profile/${searchResults[0].webToken}/talk`) && setMobileSearchActive(false);
    }
    useEffect(() => {
        if (search){
            axios.get(`/api/profile/searchProfileLimit?name=${search}&limit=10&country=${getCookie('country')}`)
            .then(profiles => {
                setSearchResults(profiles.data);
                if (profiles.data.length > 0){
                    gtagActive && constants.gtagDefined() && window.gtag('event', 'search-results', {'query': search})
                }
            })
            .catch(err=> {
                console.log(err);
                setSearchResults([]);
            })
        }
    }, [search])
    document.onclick = (e) => {
    }
    const renderMobileInput = () => {
        return(
            <div className="mobileInput">
            <div className="mobileHeader">
                <div className="mobileHeaderLeft">
                    <ArrowLeftOutlined size={100} onClick={e => {
                        setSearch('')
                        setMobileSearchActive(false)
                    }} />
                </div>
                <div className="mobileHeaderRight">
                    <Input
                    value={search}
                    suffix={<SearchOutlined/>}
                    placeholder={placeholderText}
                        bordered={false}
                        id="mainInput"
                        className="mobileHeaderInput"
                        onChange={(e) => {
                            handleChange(e)
                        }}
                        onPressEnter={handleSearch}
                    ></Input>
                </div>
            </div>
            <div className="mobileInputSuggestions">
                    { searchResults instanceof Array && searchResults.map(profile => {
                       return <div key={profile._id}>
                     <Link id="searchLink" to={`/profile/${profile.webToken}/talk`} 
                     onClick={() => {gtagActive && constants.gtagDefined() && window.gtag('event', 'search_bar_click');onHide()}}>
                        <Avatar size={40} style={{marginRight: 20}} src={profile.profilePicture === undefined ? constants.bucketUrl + "default-avatar.png" : constants.bucketUrl + profile.profilePicture }/>
                        {profile.fullName}&#09; {(profile.profileType == 'councillor') ? `Ward: ${profile.ward}` : "- Mayor"}
                        <p>{profile.neighborhood}</p>
                    </Link>
                        </div>
                    })}
                    {!(searchResults instanceof Array) && <p>{searchResults}</p>}
            </div>
        </div>
        )
    }
    return (
        !mobileSearchActive ?
    <div className="searchWrapper"  >
            <Input style={{borderRadius: 12}} suffix={<SearchOutlined/>} onFocus={() => setMobileSearchActive(true)} type={"search"} placeholder={placeholderText}/>
    </div>
    : renderMobileInput()
    )
}
export default Search;