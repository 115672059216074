
import axios from "axios";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/features/User/UserSlice";
import { Button, Image, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import constants from "../../constants/constants";
import { AnalyticsContext } from "../../context/context";
const CreatePost = ({typePost, onClick, closePost, profile, callBackPost}) => {
    const [postMedia, setPostMedia] = useState();
    const [post, setPost] = useState('');
    const [postPreview, setPostPreview] = useState();
    const [disabled, setDisabled] = useState(false);
    const user = useSelector(selectUser);
    const gtagActive = useContext(AnalyticsContext);
    let cantSubmit = disabled;
    const sendEmails = async (post) => {
        const res = await axios.post('/api/user/sendEmailsPost', post)
    }
    const submitPost = () => {
        setDisabled(true);
        const data = new FormData();
        if (post.length < 5){
            alert("Write a longer post");
            setDisabled(false);
            return;
        }

        data.append("post", JSON.stringify({
            userId: user._id,
            profileId: profile._id,
            post: post,
            typePost: typePost
        }))
        if (postMedia !== null){
            data.append("image", postMedia)
        }
        axios.post(`/api/post/createPost?token=${user.token}`, data, {headers: {        
            Accept: 'application/json',
            "Content-Type": "multipart/form-data"
            }})
        .then(res => {
            constants.gtagDefined() && gtagActive && window.gtag('event', 'create-post', {'name': user.fullName})
            setPost('');
            setPostMedia();
            closePost();
            callBackPost(res.data);
            sendEmails(res.data)
        }).catch(err => {
            console.log("err", err)
            alert(err.response.data)
            setDisabled(false);
        });
    }
    const fileHandle = (e) => {

        if (e.fileList.length == 0)
            return;
        if (e.file.type.includes("image")){
            setPostMedia(e.file.originFileObj);
            setPostPreview(URL.createObjectURL(e.file.originFileObj));
        } else {
            alert("File is not an image!");
        }
    }
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      };
    return (
        <div className="modal" onClick={onClick}>
        <div>
        {typePost == "Post" ? <p>Create a post</p> : typePost == "News" ? <p>Create a news post</p> : <p>Ask a question</p>}
        {typePost !== "QnA" && 
        <Upload multiple={false} maxCount={1} customRequest={dummyRequest} previewFile={postMedia} onChange={e => fileHandle(e)} onRemove={() => {setPostPreview(null);setPostMedia(null)}}>
            <Button icon={<UploadOutlined/>} >Click to upload</Button>
         </Upload>} 
          {/*<input id="fileItem" type='file' name='file' onChange={(e) => fileHandle(e)} />*/}
        {postMedia && <Image style={{width: 100}} src={postPreview || null}/>}
        <textarea rows={5} cols={50} placeholder="Write your post" value={post} onChange={text => setPost(text.target.value)}>

        </textarea>
        <Button disabled={disabled} onClick={() => {submitPost()}}>Post</Button>
        </div>
        </div>
    )
}
export default CreatePost;