import constants from "../../constants/constants"
import {Avatar, Button, Tag} from 'antd';
import {FacebookFilled, TwitterSquareFilled, InstagramFilled, DesktopOutlined} from '@ant-design/icons'
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "../../redux/features/User/UserSlice";
import { useContext, useEffect, useState } from "react";
import CreatePost from "./CreatePost";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AnalyticsContext } from "../../context/context";
const ProfileHeader = ({profile, tab, mobileView, addNewPost, showModal}) => {
    const user = useSelector(selectUser);
    const [createPost, setCreatePost] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [newPost, setNewPost] = useState();
    const follow = user.following?.includes(profile._id);
    const unfollow = !user.following?.includes(profile._id);
    const gtagActive = useContext(AnalyticsContext);
    const handleClick = (e) => {
        let modal = document.getElementsByClassName("modal")[0];
        if (e.target === modal){
            let ans = window.confirm("Do you want to discard this post?");
            ans && setCreatePost(false);
        }
    }
    const handleFollow = () => {
        axios.post(`/api/user/follow`, {profileId: profile._id, userId: user._id})
        .then(res => {
          dispatch(setUser({...user, ...res.data}));
          gtagActive && window.gtag('event', 'follow', {'profile': profile.fullName})
        })
      }
      const handleUnFollow = () => {
        axios.post('/api/user/unfollow', {profileId: profile._id, userId: user._id})
        .then(res => {
          dispatch(setUser({...user, ...res.data}));
          gtagActive && window.gtag('event', 'unfollow', {'profile': profile.fullName})

        })
      }

    return (
        <div className="profileHeader"> 
                <div className="profileHeaderWrapper ant-card-bordered">
                    <div style={{padding: 20}}>
                    <div style={{display:"flex", alignItems: 'center', gap: "2vw", }}>
           {profile.profileType !== "ward" && <Avatar size={100} src={profile.profilePicture ? constants.bucketUrl + profile.profilePicture : constants.bucketUrl + "default-avatar.png"} alt="profilePicture" draggable={false}/>}
            <h1 style={{display: "flex", flexDirection: 'column'}}>{profile.fullName}
            </h1>
            
                    </div>
            <div className="headerButtons">
            {unfollow && profile.profileType !== "ward" && <Button style={{width: 100, backgroundColor: constants.purple, color: "white"}} mode="elevated" shape="round" onClick={user._id ? handleFollow : showModal}>Follow</Button>}
            {follow && profile.profileType !== "ward" && <Button style={{width: 100}} mode="elevated" shape="round" onClick={handleUnFollow}>Unfollow</Button>}
            {profile.profileType !== "ward" && <Button shape="round" style={{width: 100, backgroundColor: constants.purple, color: "white"}} onClick={() => navigate('/donate', {state: {profile: profile._id}})}>Donate</Button>}
            {((user.profileId === profile._id && tab !== 1) || (user._id && user.profileId !== profile._id && tab !== 2)) && (tab !== 3)? <Button className="createPost" style={{width: 100, backgroundColor: constants.purple, color: "white"}} shape='round' onClick={() => setCreatePost(true)}>Create Post</Button>
            : <Button className="createPost" disabled={true} shape='round' onClick={showModal} style={{width: 100}} >Create Post</Button>    
        }
            
            { createPost && <CreatePost callBackPost={post => addNewPost(post)} profile={profile} closePost={() => setCreatePost(false)} onClick={handleClick} typePost={tab == 0 ? "Post" : tab == 1 ? "QnA" : "News"}/>}
            </div>
                    </div>
            <p>{profile.profileDescription}</p>
            <p>{profile.profileType === "ward" ? `All posts for ${profile.fullName}` : ((profile.profileType == 'councillor') ? `${profile.country === "CA" ? "Ward: " : "District: "}${profile.ward}` : "Mayor")}</p>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <div >
            {profile.facebookHandle && <p><a target={"_blank"} rel="noreferrer" href={`https://www.facebook.com/${profile.facebookHandle}`}><FacebookFilled/> {profile.facebookHandle}</a></p>}
            {profile.twitterHandle && <p><a target={"_blank"} rel="noreferrer" href={`https://www.twitter.com/${profile.twitterHandle}`}><TwitterSquareFilled/> {profile.twitterHandle}</a></p>}
            {profile.website && <p><a target={"_blank"} rel="noreferrer" href={profile.website}><DesktopOutlined/> {profile.website}</a></p>}
            {profile.instagramHandle && <p><a target={"_blank"} rel="noreferrer" href={`https://www.instagram.com/${profile.instagramHandle}`}><InstagramFilled/> {profile.facebookHandle}</a></p>}
            </div>
            <div style={{display: 'inline-flex', gap:'2vh', justifyContent: 'flex-end', flexWrap: 'wrap', maxWidth: "50%"}}>
              {profile.elections && profile.elections.map(election => <Tag style={{cursor: 'pointer'}} color="purple" onClick={() => navigate(`/elections/${election._id}`)}>{election.name}</Tag>)}
            </div>
            </div>
                </div>
        </div>
    )
}
export default ProfileHeader