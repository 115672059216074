import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import LandingPage from './components/LandingPage/LandingPage';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  BrowserRouter,
} from 'react-router-dom';
import axios from 'axios';
import Profile from './components/Profile/Profile' 
import Login from './components/Login/Login';
import TermsOfUse from './components/Terms/TermsOfUse';
import DataPolicy from './components/Terms/DataPolicy';
import { Provider } from 'react-redux/es';
import './index.less';
import store from './redux/store';
import Terms from './components/Terms/TermsOfUse';
import Forgot from './components/Login/Forgot';
import ResetPassword from './components/Login/ResetPassword';
import SignupChoice from './components/Signup/SignupChoice';
import browserRouter from './route';
import Router from './route';
import { AnalyticsContext } from './context/context';
axios.defaults.withCredentials = true;
console.log = function() {};
const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage/>
  },
  {
    path: "/terms",
    element: <TermsOfUse/>
  },
  {
    path: "/data-policy",
    element: <DataPolicy/>
  },
  {
    path: "/Landing",
    element: <LandingPage/>
  },    
  {
    path: "*",
    element: <h1>Error 404</h1>
  },
  {
    path: "/profile/:token",
    element: <Profile/>,
    children: [
      {path: 'talk', element: <Profile tab={0}/>},
      {path: 'qna', element: <Profile tab={1}/>},
      {path: 'news', element: <Profile tab={2}/>},
    ]
  },
  {
    path: "/login",
    element: <Login promptMessage={"Connect with candidates"}/>
  },
  {
    path: "/terms",
    element: <Terms/>
  },
  {
    path: "/signup",
    element: <SignupChoice/>
  },
  {
    path: "/forgot-password",
    element: <Forgot/>
  },
  {
    path: "/reset-password/:token",
    element: <ResetPassword/>
  }
])
const root = ReactDOM.createRoot(document.getElementById('root'));
let gtagActive = true;

root.render(
  <Provider store={store}>
    <Router/>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
